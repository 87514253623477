import { CabinCode } from '@/modules/api/application/application-contracts';

export enum ColumnId {
  FlightEquipmentType = 'flight-equipment-type',
  FlightEquipmentVersion = 'flight-equipment-version',
  FlightLid = 'FlightLid',
  FlightCapacity = 'FlightCapacity',
  FlightBookings = 'flight-bookings',
  BookingDetails = 'booking-details',
  AircraftLidLoadFactor = 'aircraft-lid-load-factor',
  FlightFareCurrency = 'flight-fare-currency',
  FlightAverageFare = 'flight-average-fare',
  CabinBookings = 'cabin-bookings',
  CabinSaleableCapacity = 'cabin-saleable-capacity',
  CabinAuthorizedCapacity = 'cabin-authorized-capacity',
  SalesConfig = 'sales-config',
  ClassCode = 'class-code',
  Boardings = 'boardings',
  Sold = 'sold',
  Stops = 'stops',
  NoShow = 'no-show',
  GroupNoShow = 'group-no-show',
  Offloaded = 'offloaded',
  GroupBoardings = 'group-boardings',
  CommercialStandbyBoardings = 'commercial-standby-boardings',
  CommercialStandbyNoBoardings = 'commercial-standby-no-boardings',
  UpgradesInto = 'upgrades-into',
  UpgradesOut = 'upgrades-out',
  DowngradesInto = 'downgrades-into',
  DowngradesOut = 'downgrades-out',
  GoShow = 'go-show',
  StaffStandbyBoardings = 'staff-standby-boardings',
  StaffStandbyNoBoardings = 'staff-standby-no-boardings',
  VolunteersForOffload = 'volunteers-for-offload',
  DeniedBoardings = 'denied-boardings',
  NoRecord = 'no-record',
  GroupSold = 'group-sold',
  NegoAllocations = 'nego-allocations',
  FlightCabinCapacity = 'FlightCabinCapacity',
  FlightCabinLid = 'FlightCabinLid',
  Revenue = 'revenue',
  BoardedRevenue = 'boarded-revenue',
  SourceSegmentAvailability = 'source-segment-availability',
  SourceFinalSegmentAvailability = 'source-final-segment-availability',
  SourceFinalGroupBookings = 'source-final-group-bookings',
  SourceGroupBookings = 'source-group-bookings',
  SourceGroupBoardings = 'source-group-boardings',
  UpdatedSegmentLimit = 'updated-segment-limit',
  UpdatedMinControl = 'updated-min-control',
  UpdatedEffectiveDate = 'updated-effective-date',
  UpdatedDiscontinueDate = 'updated-discontinue-date',
  ParentClass = 'parent-class',
  EffectiveDate = 'effective-date',
  DiscontinueDate = 'discontinue-date',
  SegmentAvailability = 'segment-availability',
  WaitListMax = 'wait-list-max',
  WaitListMaxPercentage = 'wait-list-max-percentage',
  GroupPending = 'group-pending',
  GroupWaitlisted = 'group-waitlisted',
  NegotiatedAllottedSeats = 'negotiated-allotted-seats',
  UpdatedWaitlistMax = 'updated-waitlist-max',
  UpdatedWaitlistMaxPercentage = 'updated-waitlist-max-percentage',
  FlightLineCode = 'flight-line-code',
  FareValue = 'fare-value',
  UpdatedAuthorizationUnits = 'updated-authorization-units',
  UpdatedProtection = 'updated-protection',
  SeatAvailability = 'seat-availability',
  RecommendedSeatAvailability = 'recommended-seat-availability',
  RecommendedSeatAvailabilityDifference = 'recommended-seat-availability-difference',
  ShadowRecommendedSeatAvailability = 'shadow-recommended-seat-availability',
  ShadowRecommendedSeatAvailabilityDifference = 'shadow-recommended-seat-availability-difference',
  RivalRuleName = 'rival-rule-name',
  RuleAuthorizationUnits = 'rule-authorization-units',
  RuleName = 'rule-name',
  Bookings = 'bookings',
  SourceFinalBookings = 'source-final-bookings',
  SourceBookings = 'source-bookings',
  SourceBoardings = 'source-boardings',
  /**
   * @note kept the value to avoid breaking changes with saved queries
   */
  FlightTotalFareRevenue = 'flight-total-fare-revenue',
  CabinTotalFareRevenue = 'cabin-total-fare-revenue',
  FlightAncillaryRevenue = 'flight-ancillary-revenue',
  CabinAncillaryRevenue = 'cabin-ancillary-revenue',
  FlightSurchargeRevenue = 'flight-surcharge-revenue',
  CabinSurchargeRevenue = 'cabin-surcharge-revenue',
  FlightTaxRevenue = 'flight-tax-revenue',
  CabinTaxRevenue = 'cabin-tax-revenue',
  FlightAverageAncillaryRevenuePerPax = 'flight-average-ancillary-revenue-per-pax',
  CabinAverageAncillaryRevenuePerPax = 'cabin-average-ancillary-revenue-per-pax',

  TotalFareRevenue = 'base-fare-revenue',
  AverageFare = 'average-fare',
  AuthorizationUnits = 'authorization-units',
  Protection = 'protection',
  GroupBookings = 'group-bookings',
  Waitlist = 'waitlist',
  RecommendedAuthorizationUnits = 'recommended-authorization-units',
  ShadowRecommendedAuthorizationUnits = 'shadow-recommended-authorization-units',
  RecommendedProtection = 'recommended-protection',
  RecommendedProtectionDifference = 'recommended-protection-difference',
  ShadowRecommendedProtection = 'shadow-recommended-protection',
  ShadowRecommendedProtectionDifference = 'shadow-recommended-protection-difference',
  LowestAvailableFareClass = 'lowest-available-fare-class',
  RecommendedLowestAvailableFareClass = 'recommended-lowest-available-fare-class',
  ShadowRecommendedLowestAvailableFareClass = 'shadow-recommended-lowest-available-fare-class',
  FarePrice = 'fare-price',
  BidPrice = 'bid-price',
  InventoryTacticFlightLine = 'inventory-tactic-flight-line',
  CabinCompetitorFares = 'cabin-competitor-fares',
  CabinCompetitorFaresDifference = 'cabin-competitor-fares-difference',
  CabinCompetitorFaresPercentageDifference = 'cabin-competitor-fares-percentage-difference',
  CabinRivalFares = 'cabin-rival-fares',
  CabinRivalFaresDifference = 'cabin-rival-fares-difference',
  CabinRivalFaresPercentageDifference = 'cabin-rival-fares-percentage-difference',
  SpillageSpoilageIndicator = 'spillage-spoilage-indicator',
  Autopilot = 'autopilot',
  OptimizationTactic = 'optimization-tactic',
  ShadowTacticsOptimizationTactic = 'shadow-tactics-optimization-tactic',
  PricingOptions = 'pricing-options',
  PricingTactic = 'pricing-tactic',
  Promotion = 'promotion',
  CabinSold = 'cabin-sold',
  CabinCode = 'cabin-code',
  CabinSummedRevenue = 'cabin-summed-revenue',
  LastUserActivity = 'last-user-activity',
  LastSystemUserActivity = 'last-system-user-activity',
  LastUserReview = 'last-user-review',
  CarrierCode = 'carrier-code',
  Origin = 'origin',
  Destination = 'destination',
  FlightNumber = 'flight-number',
  FlightPath = 'flight-path',
  DepartureDate = 'departure-date',
  DepartureMonth = 'departure-month',
  DepartureYear = 'departure-year',
  DepartureTime = 'departure-time',
  Ndo = 'ndo',
  DayOfWeek = 'day-of-week',
  Overnights = 'overnights',
  ArrivalDate = 'arrival-date',
  ArrivalTime = 'arrival-time',
  Tags = 'tags',
  ClosedStatus = 'closed-status',
  StatusCodeManual = 'status-code-manual',
  BlockSpace = 'block-space',
  CappedFreeFlow = 'capped-free-flow',
  UniqueFlightId = 'unique-flight-id',
  SummedRevenue = 'summed-revenue',
  Pins = 'pins',
  AircraftLeg = 'aircraft-leg',
  AircraftDepartureDate = 'aircraft-departure-date',
  AircraftDepartureTime = 'aircraft-departure-time',
  AircraftArrivalTime = 'aircraft-arrival-time',
  AircraftType = 'aircraft-type',
  FlightStatus = 'flight-status',
  LegAircraftCrossingFlights = 'leg-aircraft-crossing-flights',
  LegAircraftEquipmentTypeColumn = 'leg-aircraft-equipment-type-column',
  LegAircraftEquipmentVersion = 'leg-aircraft-equipment-version',
  LegAircraftSalesConfiguration = 'leg-aircraft-sales-configuration',
  LegAircraftLidLoadFactor = 'leg-aircraft-lid-load-factor',
  LegAircraftAuthorizedCapacity = 'leg-aircraft-authorized-capacity',
  LegCabinSaleableCapacity = 'leg-cabin-saleable-capacity',
  LegAircraftSaleableCapacity = 'leg-aircraft-saleable-capacity',
  LegAircraftCapacityLoadfactor = 'leg-aircraft-capacity-load-factor',
  GrossAvailability = 'gross-availability',
  NetAvailability = 'net-availability',
  LoadFactor = 'load-factor',
  LegCabinLoadFactor = 'leg-cabin-load-factor',
  LegCabinCapacityLoadfactor = 'leg-cabin-capacity-load-factor',
  LegCabinBookings = 'leg-cabin-bookings',
  LegCabinConnectingBookings = 'leg-cabin-connecting-bookings',
  LegCabinLocalBookings = 'leg-cabin-local-bookings',
  UpdatedAuthorizedCapcity = 'updated-authorized-capcity',
  LegCabinCapacity = 'leg-cabin-capacity',
  OverbookingFactor = 'overbooking-factor',
  OperationCapacity = 'operation-capacity',
  LegCabinAuthorizedCapacity = 'leg-cabin-authorized-capacity',
  LegCabinOverbookingFactor = 'leg-cabin-overbooking-factor',
  LegCabinLeftOverCapacity = 'leg-cabin-left-over-capacity',
  LegCabinExpectedNoShows = 'leg-cabin-expected-no-shows',
  LegCabinRecommendedOverbookingFactor = 'leg-cabin-recommended-overbooking-factor',
  LegCabinOverbookingRisk = 'leg-cabin-overbooking-risk',
  LegCabinOverbookingAutopilot = 'leg-cabin-overbooking-autopilot',
  LegCabinBlockSpace = 'leg-cabin-block-space',
  LegCabinOriginDestination = 'leg-cabin-origin-destination',
  ExpectedNoShows = 'expected-no-shows',
  RecommendedOverbooking = 'recommended-overbooking',
  OverbookingAutopilot = 'overbooking-autopilot',
  RouteFlightPath = 'route-flight-path',
  RouteKey = 'route-key',
  RouteAssignedTo = 'route-assigned-to',
  RouteDirection = 'route-direction',
  RouteCfTime = 'route-cf-time',
  RouteGroups = 'route-groups',
  RouteFareCurrency = 'route-fare-currency',
  RouteCarrierCode = 'route-carrier-code',
  SourceFlightBookings = 'source-flight-bookings',
  SourceFlightGroupBookings = 'source-flight-group-bookings',
  SourceFlightBoardings = 'source-flight-boardings',
  SourceFlightGroupBoardings = 'source-flight-group-boardings',
  SourceFlightSeatAvailability = 'source-flight-seat-availability',
  SourceFlightFinalBookings = 'source-flight-final-bookings',
  SourceFlightFinalGroupBookings = 'source-flight-final-group-bookings',
  SourceFlightRevenue = 'source-flight-revenue',
  SourceFlightBoardedRevenue = 'source-flight-boarded-revenue',
  SourceFlightFinalRevenue = 'source-flight-final-revenue',
  SourceFlightAverageFare = 'source-flight-average-fare',
  SourceFlightBoardedAverageFare = 'source-flight-boarded-average-fare',
  SourceFlightFinalAverageFare = 'source-flight-final-average-fare',
  SourceFlightFareCurrency = 'source-flight-fare-currency',

  FlightNumbers = 'flight-numbers',
  MaxLegBookings = 'max-leg-bookings',
  BookingAvailabilityStatus = 'booking-availability-status',
  PricingAdjustment = 'pricing-adjustment',
  PricingAggregation = 'pricing-aggregation',
  MinPrice = 'min-price',
  MaxPrice = 'max-price',
  LastRealTimeUpdate = 'last_real_time_update',

  // Data Science Columns
  RecommendedLaf = 'recommended-laf',
  RecommendedSa = 'recommended-sa',
  ForecastedLoadFactor = 'forecasted-load-factor',
  RecommendedFloorFareClass = 'recommended-floor-fare-class',
  ConfidenceScore = 'confidence-score',
  ForecastAverageFareAoc = 'forecast-average-fare-aoc',
  DataScienceCommentsColumn = 'data-science-comments-column',

  LastBookingDate = 'last-booking-activity',
  CabinLafFareValue = 'cabin-laf-fare-value',
  CabinCompetitorFare = 'cabin-competitor-fare',
  CabinNegoSpace = 'cabin-nego-space',
  CabinLoadFactor = 'cabin-load-factor',
  CabinCapacityLoadFactor = 'cabin-capacity-load-factor',
  PinnedClasses = 'pinned-classes',

  // Cluster Columns
  ClusterLevel = 'cluster-level',
  ClusterLevelReadOnly = 'cluster-level-read-only',
  PerformanceScore = 'performance-score',
  CabinPacingPerformanceScore = 'cabin-pacing-performance-score',
  CabinClusterColumn = 'cabin-cluster-column',
  CabinPacingPerformanceBand = 'cabin-pacing-performance-band',

  // Review Queue Navigation Columns
  Index = 'index',

  // Promotion Columns
  CabinPromotions = 'cabin-promotions',
  CabinActivePromotion = 'cabin-active-promotion',
  PublishedFare = 'published-fare',
  ForecastGeneration = 'forecast-generation',
  ForecastLearning = 'forecast-learning',

  CalendarEvent = 'calendar-event',
  CalendarEventNames = 'calendar-event-names',
  CalendarEventClusters = 'calendar-event-clusters',
}

// Flight level dynamic column Ids
export function generateFlightPickupColumnId(pickupType: 'bookings' | 'performance-band', pickupDay: number): string {
  return `${pickupType}-pickup-${pickupDay}-days`;
}

// Cabin level dynamic column Ids
export function generateCabinLevelColumnId(cabinCode: string, columnId: ColumnId): string {
  return `${cabinCode}-${columnId}`;
}

export function generateCabinLevelCompetitorColumnId(cabinCode: string, competitorCarrierCode: string, columnId: ColumnId): string {
  return `${cabinCode}-${competitorCarrierCode}-${columnId}`;
}

export function generateCabinLevelPercentageDifferenceCompetitorColumnId(
  cabinCode: string,
  competitorCarrierCode: string,
  columnId: ColumnId,
): string {
  return `${cabinCode}-${competitorCarrierCode}-percentage-difference-${columnId}`;
}

export function generateCabinLevelMinCompetitorColumnId(cabinCode: string, competitorCarrierCode: string, columnId: ColumnId): string {
  return `${cabinCode}-${competitorCarrierCode}-min-${columnId}`;
}

export function generateCabinPickupColumnId(cabinCode: string, pickupType: 'bookings' | 'performance-band', pickupDays: number): string {
  return `${cabinCode}-cabin-${pickupType}-pickup-${pickupDays}-days`;
}

export function generateCabinPerfomanceBandPickupColumnId(pickupDays: number): string {
  return `cabin-performance-band-pickup-${pickupDays}-days`;
}

// Class level dynamic column Ids
export function generateClassLevelColumnId(cabinCode: string, classCode: string, columnId: ColumnId | string): string {
  return `${cabinCode}-${classCode}-${columnId}`;
}

export function generateClassPickupColumnId(pickupDays: number): string {
  return `class-pickup-${pickupDays}-days`;
}

export function generateCustomerDefinedDataColumndId(key: string, cabinCode: CabinCode | undefined): string {
  return cabinCode ? `customer-defined-data-${cabinCode}-${key}` : `customer-defined-data-${key}`;
}
