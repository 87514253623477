import { en as ApplicationModule } from '@/modules/application/locales/en';
import { en as AuditLog } from '@/modules/audit-log/locales/en';
import { en as BookingDetails } from '@/modules/booking-details/locales/en';
import { en as ClusterManagement } from '@/modules/cluster-management/locales/en';
import { en as Control } from '@/modules/control/locales/en';
import { en as Currencies } from '@/modules/currencies/locales/en';
import { en as CustomerConfig } from '@/modules/customer-config/locales/en';
import { en as CustomerSettings } from '@/modules/customer-settings/locales/en';
import { en as Dashboard } from '@/modules/dashboard/locales/en';
import { en as DataImport } from '@/modules/data-import/locales/en';
import { en as Details } from '@/modules/details/locales/en';
import { en as Docs } from '@/modules/docs/locales/en';
import { en as EventManagement } from '@/modules/event-management/locales/en';
import { en as External } from '@/modules/external/locales/en';
import { en as Features } from '@/modules/features/locales/en';
import { en as FlightActions } from '@/modules/flight-actions/locales/en';
import { en as FlowFx } from '@/modules/flowfx/locales/en';
import { en as Grid } from '@/modules/grid/locales/en';
import { en as Insights } from '@/modules/insights/locales/en';
import { en as PacingCurveManagement } from '@/modules/pacing-curve-management/locales/en';
import { en as Promotions } from '@/modules/promotions/locales/en';
import { en as Reporting } from '@/modules/reporting/locales/en';
import { en as Revenues } from '@/modules/revenues/locales/en';
import { en as RouteManagement } from '@/modules/route-management/locales/en';
import { en as Rules } from '@/modules/rules/locales/en';
import { en as Shared } from '@/modules/shared/locales/en';
import { en as SystemSettings } from '@/modules/system-settings/locales/en';
import { en as UserManagement } from '@/modules/user-management/locales/en';
import { en as UserSettings } from '@/modules/user-settings/locales/en';

export default {
  en: {
    ...ApplicationModule,
    ...AuditLog,
    ...BookingDetails,
    ...ClusterManagement,
    ...Control,
    ...Currencies,
    ...CustomerConfig,
    ...CustomerSettings,
    ...Dashboard,
    ...DataImport,
    ...Details,
    ...Docs,
    ...EventManagement,
    ...External,
    ...Features,
    ...FlightActions,
    ...FlowFx,
    ...Grid,
    ...Insights,
    ...PacingCurveManagement,
    ...Promotions,
    ...Revenues,
    ...RouteManagement,
    ...Rules,
    ...Shared,
    ...SystemSettings,
    ...UserManagement,
    ...UserSettings,
    ...Reporting,
    eventName: 'Event Name',
    eventCluster: 'Event Cluster',
    release: 'Release',
    build: 'Build',
    audit: {
      after: 'After',
      and: 'and',
      audit: 'History',
      before: 'Before',
      cabin: 'Cabin',
      cabin_actions: 'Cabin Actions',
      change_history_for: 'Change History For',
      changed: 'changed',
      class: 'Class',
      field: 'Field',
      flightline_changes: 'Flight Changes',
      flow: 'Flow',
      imported_flight: 'Imported Flight From PSS',
      made: 'made',
      mass_apply: 'Mass Apply',
      no_cabin_changes: 'No changes were made on cabin level',
      no_changes: 'No changes detected',
      no_class_changes: 'No changes were made on class level',
      no_flightline_changes: 'No changes were made on flightline level',
      view_class_history: 'View Class History',
    },
    general: {
      general: 'General',
      for: 'For',
      aircraft: 'Aircraft',
      not_available: 'Not available',
      warning: `Your query didn't return any results. Let's try another one.`,
      error: 'An error has occurred. Please try again later.',
      report_timed_out:
        "Report couldn't be extracted, please try to decrease the requested amount of data by (for instance) limiting the observation date range",
      no_source: 'There was no default source flight found. Please select one manually.',
      not_found: 'Not Found',
      no_data: 'No Data',
      search_entities: "Please type to find what you're looking for",
      save_source: 'Save selected source flight.',
      remove_selected_source: 'Remove selected source flight.',
      flight_information: 'Flight Information',
      flight: 'Flight',
      flights: 'Flights',
      flights_table: 'FLIGHTS TABLE',
      flight_line: 'Flight Line',
      flight_number: 'Flight Number',
      flight_number_short: 'FltNbr',
      flight_control: 'Flight Control',
      flight_details: 'Flight Details',
      flight_departed_details: 'Flight Departed Details',
      origin: 'Origin',
      destination: 'Destination',
      market_group: 'Market Group',
      assignee: 'Assignee',
      currency: 'Fare Currency',
      carrier: 'Carrier',
      cabin_currency_short: 'CF Curr',
      cabin_currency: 'CF Currency',
      currency_short: 'Curr',
      source_currency_short: 'Src Curr',
      source_currency: 'Source Currency',
      direction: 'Direction',
      route_group: 'Route Group',
      route: 'Route',
      forecast_learning: 'Forecast Learning',
      forecast_generation: 'Forecast Generation',
      season: 'Season',
      of: 'of',

      welcome_back: 'Welcome back',
      setting_up_page: 'We are setting up your page',
      user_management_loading_state: 'Loading Users...',

      cabin: 'cabin | cabins',

      field: 'field | fields',
      change: 'change | changes',
      class: 'class | classes',
      day: 'day | days',
      days_ago: 'days ago',
      saleable_capacity: 'Saleable Capacity',
      sold: 'sold',
      capacity: 'Capacity',
      capacity_short: 'Capacity',
      lid: 'Lid',
      lid_short: 'Lid',

      prev_year_ind: 'Previous Year Indicators',
      yoy_rev_change: 'YoY Revenue Change',

      sds: 'Spillage and Spoilage (SDS)',
      spillage: 'Spillage',
      durable: 'Durable',
      spoilage: 'Spoilage',

      cross_filter: 'Crossfilter',
      cross_filters: 'Crossfilters',
      retrieve: 'Retrieve',
      action: 'Action',
      actions: 'Actions',
      copied_clipboard: 'Copied to clipboard!',

      appearance: 'Appearance',

      select_category: 'Please select a category first',
      load_filter: 'Load Filter',

      leg_uppercase: 'LEG',
      o_d_uppercase: 'O&D',

      carrier_code_short: 'Carrier',
      carrier_code: 'Carrier Code',
      navigation: 'Navigation',
      ndo: 'NDO',
      ndo_explained: 'Number of Days Out',
      departure_date: 'Departure Date',
      start_date: 'Start Date',
      end_date: 'End Date',
      to: 'To',
      than: 'Than',

      results: 'Results',
      selected: 'Selected',
      clear_filter: 'Clear Filter',
      clear_all_filters: 'Clear All Filters',
      clear_selection: 'Clear Selection',
      edit: 'Edit',
      review: 'Review',
      review_flight_details: 'Review Flight Details',

      download_csv: 'Download as .csv',
      download_excel: 'Download as .xlsx',
      preview: 'Preview',
      get_preview: 'Get preview',
      get_full_data: 'Get full data',
      check_out_these_flights: 'Check out these flights',

      filter_fields: 'Filter Fields',
      reporting: 'Reporting',

      select_all: 'Select all',
    },

    to: 'to',
    than: 'than',

    query_toolbar: {
      unsaved: 'Unsaved query',
      select: 'Select a query',
      report: 'Report',
      query: 'Query',

      save_as_new: 'Save as a new query',
      edit_the_existing: 'Edit the existing query',
      save_new: 'Save a New Query',
      deleted: 'Query is deleted',
      name: 'Name',
      fixed_date_range: 'Fixed Date Range',
      load: 'Load query',
      actions: 'Query Actions',
      clear: 'Clear query',

      edit_query: 'Edit query',
      save_query: 'Save query',
      copy_query: 'Copy as new query',
      delete_query: 'Delete this query',
      deselect_query: 'Deselect this query',

      errors: {
        no_queries_found: 'No queries found',
        minimum_name_length: 'Please make sure the name contains at least four characters.',
        same_name: 'There is already a query saved with the same name. ' + 'Please provide a different name for this one.',
        no_queries: 'No queries yet. Please make a search first to save one.',
        referenced_query: 'This query is part of a routine in FlowFx. Please remove the query from the routine and try again.',
        unsupported_grid_filters:
          'The query contains grid filters that cannot be used in a routine automation, and the query is used by a routine automation.',
      },
    },

    flight_actions_group: {
      same_name: 'There is already a quick action saved with the same name. ' + 'Please provide a different name for this one.',
      minimum_name_length: 'Please make sure the name contains at least four characters.',
      accessible_actions: ' Directly accessible actions',
      accessible_via_button: "Actions available via 'More...' button",
      referenced_record: 'This quick action is part of a routine in FlowFx. Please remove the quick action from the routine and try again.',
    },

    filter_builder: {
      add_rule: 'Add Rule',
      add_group: 'Add Group',
      delete_group: 'Delete Group',

      and: 'and',
      equals: 'equals',
      nequals: 'does not equals',
      greater_than: 'is greater than',
      greater_than_equals: 'is greater than or equals to',
      less_than: 'is less than',
      less_than_equals: 'is less than or equals to',
      between: 'is between',
      not_between: 'is not between',
      is_empty: 'is empty',
      is_not_empty: 'is not empty',
      is_like: 'is like',
      is_not_like: 'is not like',
    },

    dynamic_filters: {
      eventName: 'Event Name',
      eventCluster: 'Event Cluster',
      flight: 'Flight #',
      cluster: 'Cluster',
      hub: 'Hub',
      flightPath: 'Flight Path',
      departureDate: 'Departure Date',
      ndoDepartureDate: 'dDate',
      departureDateRange: 'Date',
      dayOfWeek: 'Day of Week',
      origin: 'Origin',
      carrierCode: 'Carrier Code',
      destination: 'Destination',
      userId: 'User Id',
      tagId: 'Tags',
      tagsAdd: 'Tags (Add)',
      tagsRemove: 'Tags (Remove)',
      aircraftType: 'Equipment Type',
      equipmentType: 'Equipment Type',
      optimizationProfile: 'Optimization Profile',
      optimizationTactic: 'Optimization Tactic',
      class: 'Class',
      apply: 'Apply filters',
      add_new_field: 'Add New Field',
      flightNumber: 'Flight Number',
      routeGroupId: 'Route Group',
      change_au: 'Set AUs',
      change_pr: 'Set Protections',
      change_effective_date: 'Set Effective Date',
      change_segment_limit: 'Set Segment Limit',
      change_updated_discontinue_date: 'Set Discontinue Date',
      change_min_control: 'Set Min Threshold',
      change_seat_availability: 'Set Seat Availability',
      change_overbooking_factor: 'Set OVB Factor',
      change_optimisation_tactic: 'Set Active Optimization Tactic',
      swap_optimisation_tactics: 'Swap Active and Shadow Tactics',
      swapping: 'Swapping',
      change_shadow_optimisation_tactic: 'Set Shadow Optimization Tactic',
      toggle_autopilot: 'Set Autopilot',
      laf_n_up: 'Move LAF Up',
      laf_n_down: 'Move LAF Down',
      opt_profile_level_n_up: 'Move Active OP Level Up',
      opt_profile_level_n_down: 'Move Active OP Level Down',
      shadow_opt_profile_level_n_up: 'Move Shadow OP Level Up',
      shadow_opt_profile_level_n_down: 'Move Shadow OP Level Down',
      cluster_level_up: 'Move Cluster Level Up',
      cluster_level_down: 'Move Cluster Level Down',
      set_cluster_level: 'Set Cluster Level',
      set_below_zero: 'Set AU Below LAF to 0',
      specific_class: 'Set LAF to a Specific Class',
      set_sa_of_laf: 'Set Availability in LAF',
      increase_sa_of_laf: 'Increase Availability of LAF',
      flightNumberRange: 'flightNumberRange',
      add_optimisation_profile_level: 'Add Opt. Profile',
      remove_optimisation_profile_level: 'Remove Opt. Profile',
      captureDate: 'Capture Date',
      observation_date: 'Observation Date',
      observation_date_range: 'Observation Date Range',
      apply_recommended_au: 'Apply rAUs',
      change_unbalanced_adjustment: 'Set Unbalanced Adjustments',
      change_balanced_adjustment: 'Set Balanced Adjustments',
      resolve_leftover: 'Resolve Leftover Capacity',
      manual_adjustments: 'Manual Adjustments',
      apply_data_science_rlaf: 'Apply Data Science rLAF & rSA',
      pricing: 'Pricing',
      tactical_adjustment: 'Tactical Adjustment',
      tactical_adjustment_level_n_up: 'Move Tac Adj Up',
      tactical_adjustment_level_n_down: 'Move Tac Adj Down',
      price_increment: 'Price Increment',
      set_laf_based_on_competitor: 'Set LAF Based on Competitor',
    },

    display_name: 'Display Name',
    active: 'Active',
    inactive: 'Inactive',
    add_role: 'Add a role',
    add_another_role: 'Add another role',
    form: {
      validate_name: 'Please input name',
      validate_email: 'Please input email address',
      validate_valid_email: 'Please input correct email address',
      validate_role: 'Please add at least 1 role',
      validate_phone_number: 'Please input phone number',
      validation_errors: {
        default: 'Something went wrong, please try again',
        automation_has_no_actions: 'Automation has no actions',
        date_ranges_overlap: 'Date ranges overlap',
        departure_date_ranges_shortened: "Departure date range can't be shortened",
        no_flights: 'No planned flights found',
        unauthorized: 'Unauthorized',
        unique_name: 'Name already exists',
        unique_email: 'Email already exists',
        duplicate_phone_number: 'A user with this phone number already exists',
        invalid_password_update: "Password doesn't meet requirements",
        invalid_email: 'Email is not valid',
        invalid_phone_number: 'Phone number is not valid',
        private_user_update: 'Not allowed to update private user',
        required_user_and_reason: "The 'Updated by' and the 'Change reason' fields are mandatory.",
        user_inactive: 'User {u} has been deactivated. Please select different user(s) from the list',
      },
    },

    command_panel: 'Command panel',
    save_command: 'Save Command',

    offset: 'Offset',
    set_seats: 'Set available seats',
    distribution: 'Distribution',

    inv_tactics: 'Inventory Tactics',
    flight_cabin_inv_tactics: 'Flight Cabin Inventory Tactics',

    leg_information: 'Leg Information',
    leg_aircraft_information: 'Leg Aircraft Information',
    leg_cabin_information: 'Leg Cabin Information',

    tags: 'Tags',
    new_tag: 'New Tag',
    new_tag_too_long: 'Tag(s) "{tags}" contain(s) too many characters, max {length}',
    last_user_activity: 'Last User Action',
    real_time_bookings_update: 'Last RT Update',
    request_realtime_bookings: 'Request Real-time Bookings',
    last_user_action: 'Last User Action',
    last_user_action_short: 'Last User Action',
    last_eddy_action: 'Last Eddy Action',
    last_eddy_action_short: 'Last Eddy Action',
    last_user_review: 'Last User Review',
    last_user_review_short: 'Last Review',
    sale_status: 'Status',
    compare_flights: 'Compare To Another Flight',
    compare: 'Compare',
    correct_unordered_au_structure: 'Correct Unordered AU Structure',
    unordered_sa_structure: 'Unordered SA Structure',
    unordered_au_structure: 'Unordered AU Structure',

    required_fields: 'Required Fields',
    required_fields_searching: 'For searching, the following fields are required',

    competitor_types: 'Competitor Types',
    how_to_match_competitor: 'How to match Competitor',

    search: 'Search',
    searching: 'Searching',
    show_all: 'Show all',
    flights_count: '{count} Flight | {count} Flights',
    flights_selected: '{selection} of {count} Flight Selected | {selection} of {count} Flights Selected',
    current_of_total: '1 - {current} of {total}',

    // navigation
    prev: 'Prev',
    next: 'Next',
    dy: 'Dy',
    wk: 'Wk',

    day_of_week_short: 'DoW',
    day_of_week: 'Day of Week',
    all: 'All',
    any: 'Any',

    flight_status: {
      none: 'None',
      new_flight: 'New Flight',
      boarding: 'Boarding',
      departed: 'Departed',
      received_boarding: 'Has received boarded figures',
      received_pnl: 'Has received PNL (Passenger Name List)',
      unknown_status: 'Unknown status',
    },

    // tooltips
    required_field: 'This field is required',
    font_size: 'Font Size',
    change_display_order: 'Display Order',
    copy_to_clipboard: 'Copy to Clipboard',
    filter_type: 'Route Filter Type',
    pickups: 'Pickups',
    bookings_pickups: 'Bookings Pickup',
    performance_band_pickups: 'Perf Band Pickup',
    revenue_pickups: 'Revenue Pickup',

    share_link: 'Share Link',
    send_email: 'Send Email',

    // message box
    changes_will_not_be_saved: 'Changes that you made will not be saved.',
    unsaved_changes: "There are unsaved changes. If you leave, you'll lose them. Are you sure you want to continue?",
    system_restart_warning: 'Please be aware that this will restart the system for all users. Are you sure you want to continue?',
    unique_report_name_needed: 'Report should have a unique name',
    warning: 'Warning',
    leave_without_saving: 'Leave (Without Saving)',
    leave_form: 'Leave form?',
    no_changes_detected: 'No changes were detected. Please make some changes to the flight first and then try saving.',
    no_changes_to_revert: 'No changes to revert were detected',
    confirm_changes: 'Confirm changes?',
    cancel_changes: 'Cancel unsaved changes?',
    confirm_delete: 'Are you sure to delete this?',
    ok: 'OK',
    cancel: 'Cancel',
    leave: 'Leave',
    success_message: 'Your changes have been applied',
    successful_report_copy: 'Copied the report to {name}. Your changes have been applied',
    post_departure_empty_title: 'Incomplete data',
    post_departure_empty_message: 'It seems that this flight has not been updated with post departure information',
    refresh_title: 'Outdated results',
    refresh_message: 'Data may have changed due to the updates made. Run the search again to get the latest results!',
    failed_request_message: 'Something went wrong',
    max_digit: 'Please enter max 3 digits',
    bulk_availability_change: `Segment limit, effective and discontinue dates can influence the availability set.`,
    bulk_overbooking_change: 'Overbooking happens by leg, multi-leg segments will not be changed.',
    bulk_adjustment_change: 'Adjustment happens by leg, multi-leg segments will not be changed.',
    bulk_revert: 'Reverting will revert fields received from the PSS, not tags, autopilot or other Eddy specific fields',
    realtime_bookings_request_success:
      'Request to get updated bookings has been successfully made. It may take up to 30 minutes for Amadeus to process it and respond. Please check back the flight to see if the real-time bookings are updated.', // grids
    // inventory tactics
    cabin_short: 'Cab',
    cabin: 'Cabin',
    bookings_short: 'Bk',
    bookings: 'Bookings',
    connecting_bookings: 'Through/Connecting Bookings',
    connecting_bookings_short: 'Conx Bk',
    local_bookings: 'Local Bookings',
    local_bookings_short: 'Local Bk',
    seat_availability_short: 'SA',
    recommended_seat_availability_short: 'rSA',
    recommended_seat_availability_difference_short: 'Δ rSA',
    seat_availability: 'Seat Availability',
    cabin_seat_availability: 'Cabin {cabin} Seat Availability',
    lowest_available_fare_short: 'LAF',
    lowest_available_fare: 'Cabin {cabin} Lowest Available Fare',
    demand: 'Demand',
    forecast_short: 'Fcst',
    forecast: 'Forecast',
    active_optimisation_tactics: 'Active Tactics',
    shadow_tactics: 'Shadow Tactics',
    optimisation_tactics_short: 'Opt Tactic',
    active_optimisation_tactic_short: 'Active Opt Tactic',
    shadow_optimisation_tactic_short: 'Shadow Opt Tactic',
    optimisation_tactics: 'Optimization Tactic',
    active_optimisation_tactic: 'Active Optimization Tactic',
    shadow_optimisation_tactic: 'Shadow Optimization Tactic',
    swap_with_active_tactic: 'Swap with active tactic',
    swap_with_shadow_tactic: 'Swap with shadow tactic',
    tactic_adjustment: 'Tactic Adjustment',
    tactic_adjustment_short: 'Tac Adj',
    pricing_tactic: 'Pricing Tactic',
    pricing_tactic_short: 'Pricing Tac',
    optimisation_profile: 'Optimization Profile',
    optimisation_profile_level: 'Optimization Profile Level',
    optimisation_tactic_profile: 'Optimization Tactic & Profile',
    autopilot: 'Autopilot',
    autopilot_short: 'Auto',
    sds_short: 'SDS',
    sds: 'Spill/Spoil Distribution Signal',
    closed_short: 'Cls',
    closed: 'Closed',
    status_code_manual_short: 'Man',
    status_code_manual: 'Manual',
    codeshare_block_space_short: 'CBS',
    codeshare_block_space: 'Codeshare Block Space',
    codeshare_capped_free_flow_short: 'CCF',
    codeshare_capped_free_flow: 'Codeshare Capped Free Flow',
    origin_short: 'Org',
    destination_short: 'Des',
    flight_path: 'Flight Path',
    overnights_short: 'O/N',
    overnights: 'Number of Overnights',
    overnight: 'Overnight',
    on: 'On',
    off: 'Off',
    enabled: 'Enabled',
    disabled: 'Disabled',
    open: 'Open',
    edit: 'Edit',
    manual: 'Manual',
    continuous: 'Continuous',
    profiles: 'Profiles',
    byo: 'BYO',
    dsOp: 'Forecast+Dynamic Program',
    price_increment_short: 'Price Inc',
    lid_load_factor: 'Lid Load Factor',
    capacity_load_factor: 'Capacity Load Factor',
    leg: 'Leg',
    departure_date_short: 'dDate',
    departure_date: 'Departure Date',
    departure_time_short: 'dTime',
    departure_time: 'Departure Time',
    arrival_date_short: 'aDate',
    arrival_date: 'Arrival Date',
    arrival_time_short: 'aTime',
    arrival_time: 'Arrival Time',
    leg_authorized_capacity_short: 'aCap',
    leg_authorized_capacity: 'Authorized Capacity',
    leg_saleable_capacity_short: 'sCap',
    leg_saleable_capacity: 'Saleable Capacity',
    laf_cabin: 'LAF - Cabin',
    laf_actions: 'LAF Actions',
    flight_actions: 'Flight Actions',

    // leg cabin
    saleable_capacity_short: 'sCap',
    saleable_capacity: 'Saleable Capacity',

    operating_capacity_short: 'oCap',
    operating_capacity: 'Operating Capacity',
    authorized_capacity_short: 'aCap',
    authorized_capacity: 'Authorized Capacity',

    customer_defined_data_short: 'BYO Data',
    customer_defined_data: 'Bring Your Own Data',
    flight_customer_defined_data_short: "Flight {'|'} BYO Data",
    flight_customer_defined_data: "Flight {'|'} Bring Your Own Data",
    cabin_customer_defined_data_short: "Flight {'|'} Cabin {cabin} BYO Data",
    cabin_customer_defined_data: "Flight {'|'} Cabin {cabin} Bring Your Own Data",

    net_availability_short: 'NAV',
    net_availability: 'Net Availability',
    gross_availability_short: 'GAV',
    gross_availability: 'Gross Availability',
    overbooking_number_short: 'OVB#',
    overbooking_number: 'Overbooking Number',
    r_lid: 'rLid',
    r_gav: 'rGAV',
    block_space_short: 'Block',
    block_space: 'Block Space',
    no_block_space_data: 'No block space data available for this cabin / leg',

    updated_segment_limit: 'Segment Limit',
    updated_min_control: 'Minimum Threshold',

    // inventory
    flight_line_code_short: 'Flight Line/Cls',
    flight_line_class: 'Flightline / Class',
    fare_value: 'Fare Value',
    updated_authorization_units: 'Authorization Units',
    updated_authorization_units_short: 'AU',
    pr_short: 'Pr',
    pr: 'Protections',
    rival_rule_name: 'Rival Rule Name',
    rival_rule_name_short: 'Rival Rule',
    rule_authorization_units: 'Rule Authorization Units',
    rule_authorization_units_short: 'rlAU',
    linked_class_rule_name: 'Linked Class Rule Name',
    linked_class_rule_name_short: 'Linked Class Rule',
    sl_short: 'SL',
    sl: 'Segment Limit',
    rv: 'Fare Revenue',
    rv_short: 'Fare Revenue',
    average_fare_short: 'AvgFare',
    average_fare: 'Average Fare',
    c_flags_short: 'cFlags',
    c_flags: 'Captured Flags',
    p_y_brds_short: 'PYBrd',
    p_y_brds: 'Previous Year Boardings',
    p_y_bk_short: 'PYBk',
    p_y_bk: 'Previous Year Bookings',
    r_au_short: 'rAU',
    r_au: 'Recommended Authorization Units',
    r_pr_short: 'rPr',
    r_pr: 'Recommended Protections',
    r_pr_difference_short: 'Δ rPr',
    r_pr_difference: 'Recommended Protections Variance',
    parent_class_short: 'ParentCls',
    parent_class: 'Parent Class',
    c_bk_short: 'cBk',
    c_bk: 'Captured Bookings',
    c_max_leg_bk_short: 'cMaxLegBk',
    c_max_leg_bk: 'Captured Max Leg Bookings',
    c_tc_bk_short: 'cTCBk',
    c_tc_bk: 'Captured Thru/Conn Bookings',
    c_grp_bk_short: 'cGrpBk',
    c_grp_bk: 'Captured Group Bookings',
    c_grp_op_short: 'cGrpOp',
    c_grp_op: 'Captured Group Optional',
    c_grp_wl_short: 'cGrpWL',
    c_grp_wl: 'Captured Group Waitlisted',
    c_wl_short: 'cWL',
    c_wl: 'Captured Waitlisted',
    c_nego_short: 'cNego',
    c_nego: 'Captured Nego Allocations',
    c_au_short: 'cAU',
    c_au: 'Captured Au',
    c_pr_short: 'cPr',
    c_pr: 'Captured Protections',
    c_min_short: 'cMin',
    c_min: 'Captured Min',
    c_effe_date_short: 'cEffeDate',
    c_effe_date: 'Captured Effective Date',
    c_disc_date_short: 'cDiscDate',
    c_disc_date: 'Captured Discontinue Date',
    sa_short: 'Sa',
    c_sa_short: 'cSA',
    c_sl_short: 'cSl',
    c_sl: 'Captured Segment Limit',
    c_wl_max_short: 'cWLMax',
    c_wl_max: 'Captured Waitlist Max',
    c_wl_max_perc_short: 'cWLMax%',
    c_wl_max_perc: 'Captured Waitlist Max %',
    c_rv_short: 'cRv',
    c_rv: 'Captured Revenues',
    max_leg_bk_short: 'MaxLegBk',
    max_leg_bk: 'Max Leg Bookings',
    tc_bk_short: 'TCBk',
    tc_bk: 'Thru/Conn Bookings',
    grp_bk_short: 'GrpBk',
    grp_bk: 'Group Bookings',
    grp_op_short: 'GrpPnd',
    grp_op: 'Group Pending',
    grp_wl_short: 'GrpWL',
    grp_wl: 'Group Waitlisted',
    wl_short: 'WL',
    wl: 'Waitlisted',
    nego_short: 'Nego',
    nego: 'Nego Allocations',
    min_au_short: 'minAu',
    min_au: 'Min Au',
    max_au_short: 'maxAu',
    max_au: 'Max Au',
    st_au_short: 'stAu',
    st_au: 'Static Au',
    min_pr_short: 'minPr',
    min_pr: 'Min Protections',
    max_pr_short: 'maxPr',
    max_pr: 'Max Protections',
    min_control_short: 'Min',
    min_control: 'Minimum Threshold',
    updated_effective_date_short: 'EffeDate',
    updated_effective_date: 'Effective Date',
    updated_discontinued_date_short: 'DiscDate',
    updated_discontinued_date: 'Discontinue Date',
    x_ndo_short: 'xNdo',
    x_ndo: 'Ndo Close',
    flags_short: 'Flags',
    flags: 'Flags',
    wl_max_short: 'WLMax',
    wl_max: 'Waitlist Max',
    wl_max_perc_short: 'WLMax%',
    wl_max_perc: 'Waitlist Max %',
    trend_short: 'Trend',
    trend: 'Recent Trend',
    drop_perc_short: 'Drop%',
    drop_perc: 'Drop Out Percentage',
    u_bk_short: 'uBk',
    u_bk: 'Source Average Bookings',
    u_brd_short: 'uBrd',
    u_brd: 'Source Average Boardings',
    fcst_bk_short: 'FcstBk',
    fcst_bk: 'Forecast Bookings',
    fcst_grp_bk_short: 'FcstGrpBk',
    fcst_grp_bk: 'Forecast Group Bookings',
    fcst_brd_short: 'FcstBrd',
    fcst_brd: 'Forecast Boardings',
    fcst_rv_short: 'FcstRv',
    fcst_rv: 'Forecast Revenues',
    emsra_opt_au_short: 'EMSRa Opt Au',
    emsra_opt_au: 'EMSRa Optimal Au',
    emsrb_opt_au_short: 'EMSRb Opt Au',
    emsrb_opt_au: 'EMSRb Optimal Au',
    goal_opt_au_short: 'Goal Opt Au',
    goal_opt_au: 'Goal Optimal Au',
    r_fcst_bk_short: 'rFcstBk',
    r_fcst_bk: 'Recommended Forecast Bookings',
    r_fcst_grp_bk_short: 'rFcstGrpBk',
    r_fcst_grp_bk: 'Recommended Forecast Group Bookings',
    r_fcst_brd_short: 'rFcstBrd',
    r_fcst_brd: 'Recommended Forecast Boardings',
    r_fcst_rv_short: 'rFcstRv',
    r_fcst_rv: 'Recommended Forecast Revenues',
    p_y_grp_bk_short: 'PYGrpBk',
    p_y_grp_bk: 'Previous Year Group Bookings',
    p_y_grp_op_short: 'PYGrpOp',
    p_y_grp_op: 'Previous Year Group Pending',
    p_y_grp_wl_short: 'PYGrpWL',
    p_y_grp_wl: 'Previous Year Group Waitlisted',
    p_y_wl_short: 'PYWL',
    p_y_wl: 'Previous Year Waitlisted',
    p_y_ns_short: 'PYNS',
    p_y_ns: 'Previous Year Nego Alloc Bookings',
    p_y_max_leg_bk_short: 'PYMaxLegBk',
    p_y_max_leg_bk: 'Previous Year Max Leg Bookings',
    p_y_tc_bk_short: 'PYTCBk',
    p_y_tc_bk: 'Previous Year Thru/Conn Bookings',
    p_y_final_bk_short: 'PYFinalBk',
    p_y_final_bk: 'Previous Year Final Bookings',
    p_y_final_grp_bk_short: 'PYFinalGrpBk',
    p_y_final_grp_bk: 'Previous Year Final Group Bookings',
    p_y_final_nego_short: 'PYFinalNego',
    p_y_final_nego: 'Previous Year Nego Alloc Bookings',
    p_y_max_leg_brd_short: 'PYMaxLegBrd',
    p_y_max_leg_brd: 'Previous Year Max Leg Boardings',
    p_y_tc_brd_short: 'PYTCBrd',
    p_y_tc_brd: 'Previous Year Thru/Conn Boardings',
    min_price: 'Minimum Price',
    min_price_short: 'Min Price',
    max_price: 'Maximum Price',
    max_price_short: 'Max Price',

    // Source Flight
    source_fn_bk_short: 'SrcFnlBk',
    source_fn_bk: 'Source Final Bookings',
    source_fn_seg_avail_short: 'SrcFnlSA',
    source_fn_seg_avail: 'Source Final Segment Availability',
    source_seg_avail_short: 'SrcSA',
    source_seg_avail: 'Source Segment Availability',
    source_seat_avail_short: 'SrcSA',
    source_seat_avail: 'Source Seat Availability',
    source_fn_grp_boa_short: 'SrcFnlGrpBrd',
    source_fn_grp_boa: 'Source Final Group Boardings',
    source_fn_grp_bk_short: 'SrcFnlGrpBk',
    source_fn_grp_bk: 'Source Final Group Bookings',
    source_grp_bk_short: 'SrcGrpBk',
    source_grp_bk: 'Source Group Bookings',
    source_grp_boa_short: 'SrcGrpBrd',
    source_grp_boa: 'Source Group Boardings',
    source_bk_short: 'SrcBk',
    source_bk: 'Source Bookings',
    source_brds_short: 'SrcBrd',
    source_brds: 'Source Boardings',
    source_avg_fare_short: 'SrcAvgFare',
    source_avg_fare: 'Source Average Fare',
    source_brd_avg_fare_short: 'SrcBrdAvgFare',
    source_brd_avg_fare: 'Source Boarded Average Fare',
    source_fn_avg_fare_short: 'SrcFnlAvgFare',
    source_fn_avg_fare: 'Source Final Average Fare',
    source_rev_short: 'SrcRev',
    source_rev: 'Source Revenue',
    source_brd_rev_short: 'SrcBrdRev',
    source_brd_rev: 'Source Boarded Revenue',
    source_fn_rev_short: 'SrcFnlRev',
    source_fn_rev: 'Source Final Revenue',
    sold_out: 'N/A',
    source_flight: 'Source Flight',
    source_final_revenue: 'Source Final Revenue',
    source_no_show: 'Source No Shows',
    source_go_show: 'Source Go Shows',
    source_group_no_show: 'Source Group No Shows',
    source_denied_boardings: 'Source Denied Boardings',
    source_flight_line: 'Source Flightline',
    source_departure_date: 'Source Departure Date',
    source_departure_time: 'Source Departure Time',
    source_departure_date_short: 'Source dDate',
    source_carrier: 'Source carrier',
    source_flights_assigned_to_flights: 'Source flights assigned to flights',
    assign_source_flight: 'Source Flight Assignments',

    // Departed Grid
    code: 'Code',
    code_short: 'Flight Line/Cls ',
    boardings: 'Boardings',
    boardings_short: 'Brd',
    sold: 'Sold',
    sold_short: 'Sold',
    nos: 'No show passengers',
    nos_short: 'NOS',
    gns: 'Group no show passengers',
    gns_short: 'GNS',
    off_loaded: 'Offloaded passengers',
    off_loaded_short: 'OFF',
    gbo: 'Group Boardings',
    group_boardings: 'Group Boardings',
    gbo_short: 'GBO',
    cbo: 'Commercial stand-by boardings',
    cbo_short: 'CBO',
    cnb: 'Commercial stand-by not boardings',
    cnb_short: 'CNB',
    ugi: 'Upgrades into',
    ugi_short: 'UGI',
    ugo: 'Upgrades out',
    ugo_short: 'UGO',
    dgi: 'Downgrades into',
    dgi_short: 'DGI',
    dgo: 'Downgrades out',
    dgo_short: 'DGO',
    gos: 'Go show passengers',
    gos_short: 'GOS',
    sbo: 'Staff stand-by boardings',
    sbo_short: 'SBO',
    snb: 'Staff stand-by not boardings',
    snb_short: 'SNB',
    vol: 'Voluntary offloaded passengers',
    vol_short: 'VOL',
    dnb: 'Denied Boardings',
    denied_boardings: 'Denied Boardings',
    dnb_short: 'DNB',
    nor: 'No record',
    nor_short: 'NOR',
    grpsold: 'Group sold',
    grpsold_short: 'GrpSold',
    negoalloc: 'Nego allocations',
    negoalloc_short: 'NGO',
    negotiated_space: 'Negotiated Space',
    revenue: 'Fare Revenue',
    revenue_short: 'Fare Revenue',

    close: 'Close',
    flight_line_class_history: `Class history for {0}`,
    who: 'Who',
    when: 'When',
    fare: 'Own CF',
    own_fare: 'Own Fare',
    fare_explained: 'Own Competitor Fare',
    cabin_x_fare_value: 'Cabin {cabin} Fare Value',
    cabin_competitor_fares: 'Cabin {cabin} Competitor Fares',
    rival_fares: 'Rival Fares',
    competitive_fare_short: 'CF',
    airline: 'Airline',
    all_competitor_fares: 'All Competitor Fares',
    competitor: 'Competitor',
    one_way_return: 'OW / RT',
    one_way: 'OW',
    return: 'RT',
    quality: 'Quality',
    point_of_sale_short: 'POS',
    source: 'Source',
    captured: 'Captured',
    captured_explained: 'Captured timestamp increases in boldness the older the data is',
    departure: 'Departure',
    hour_short: 'h',
    minute_short: 'm',
    same_time: 'Same time',
    load_factor_short: 'LF',
    authorized_capacity_load_factor_short: 'aLF',
    authorized_capacity_load_factor: 'Authorized Capacity Load Factor',
    saleable_capacity_load_factor_short: 'sLF',
    saleable_capacity_load_factor: 'Saleable Capacity Load Factor',

    load_factor: 'Load Factor',
    capacity_load_factor_short: 'cLF',
    index: 'Index',
    grid_side_bar: {
      columns: 'Columns',
      column_actions: 'Actions',
      filters: 'Filters',
    },

    // commands
    commands: {
      save_and_next: 'Save all information and Next',
      save_and_prev: 'Save all information and previous',
      save_all: 'Save all',
      save_leg: 'Save leg information',
      save_inv: 'Save inventory tactics',
      save_all_flights: 'Save inventory all flights',
      save_curr_flights: 'Save inventory current flights',
    },

    user_action: 'User Action',

    actions: {
      add_user: 'Add user',
      apply: 'Apply',
      apply_changes: 'Apply Changes',
      leave_unassigned: 'Leave unassigned',
      cancel: 'Cancel',
      close: 'Close',
      confirm: 'Confirm',
      delete: 'Delete',
      edit: 'Edit',
      copy: 'Copy',
      edit_name: 'Edit Name',
      extract: 'Extract',
      label: 'Actions',
      load: 'Load',
      login: 'Log in',
      logout: 'Log out',
      pick_a_day: 'Pick a day',
      reset: 'Reset',
      restart: 'Restart',
      revert_changes: 'Revert Changes',
      run: 'Run',
      save: 'Save',
      save_and_close: 'Save & Close',
      save_and_next: 'Save & Next',
      saveAs: 'Save as',
      select: 'Select',
      view: 'View',
      what_if: 'What if',
      schedule_settings: 'Schedule Settings',
    },
    validations: {
      min_length: '{field} should be at least {length} characters',
      required: '{field} is required',
    },
    login: {
      title: 'Sign in to Eddy',
    },
    messages: {
      service_unavailable: 'Sorry, one of our utils is not available',
      unable_to_get_system_config: 'Unable to get the system configuration',
      unable_to_log_in: 'Unable to log in',
      this_cant_be_undone: `This can't be undone!`,
      no_flight_found: 'No flight data to display. Use the navigation above to search for a flight.',
      preview_fail: 'Something went wrong with the calculations to preview the changes.',
      conflict: 'Conflicting writes to database are occurring, please try again',
      loaded: `Loaded '{0}'`,
      saved: `Saved '{0}' successfully`,
      batch_running:
        'Currently, Eddy is loading your daily transaction file. During this processing, you cannot make changes to the system. Often this processing takes  up to 90 minutes, so please try again at a later point in time.',
      source_flight_saved: 'Source Flight Saved',
      source_flight_removed: 'Source Flight Removed',
      system_restart_succes: 'System restart has been successfully initiated. Please wait for 5 minutes and login again.',
      refine_search: 'Search results are over 20K, please refine your search by using the filters',
      invalid_filter: "One or more filters are invalid. Therefore, the report couldn't be saved.",
      no_planned_flights: 'No planned flights have been found. Please update your query.',
      response_overload: 'Too many results or an unexpected issue occurred. Please refine your search criteria and try again.',
    },

    settings: {
      promotion_management: 'Promotion Management',
      settings: 'Settings',
      system_settings: 'System Settings',
      customer_config: 'Customer Config',
      loading: 'Loading...',
      notifications: 'Notifications',
      user_management: 'Users & Permissions',
      pricing_multiplier: 'Pricing Multiplier',
      pricing_multiplier_explanation: 'Pricing Aggregation Multiplier for Continuous Pricing',
    },
    titles: {
      edit_x_flights: 'Edit {n} Flight | Edit {n} Flights',
      edit_user: 'Edit User',
      add_user: 'Add User',
      uh_oh: 'Uh oh!',
      class_properties: 'Class Properties',
      edit_continuous_pricing: 'Edit Continuous Pricing',
    },

    pss: 'PSS Provider',

    // bulk cascader
    flight: 'Flight',
    add: 'Add',
    remove: 'Remove Tags',
    revert_to_nightly_capture: 'Revert To Nightly Capture',
    send_flights_to_pss: 'Send Flights To PSS',
    class: 'Class',
    maintain_protections: 'Maintain Protections',
    dont_maintain_protections: `Don't Maintain Protections`,
    distribute_evenly: 'Distribute evenly',
    authorized_capacity_actions: 'Authorized Capacity Actions',
    add_tags: 'Add Tags',

    configure_filters_explanation:
      'Add or remove the available filter fields that are displayed when you initially visit the page. To change the order, drag and drop it to the right position.',
    configure_quick_actions_explanation:
      'Add or remove up to six available action buttons that are displayed when you initially visit the page. To change the action button order, drag and drop it to the right position.',
    configure_filters: 'Configure Filters',
    configure_flight_control: 'Configure Flight Control',
    configure_flight_details: 'Configure Flight Details',
    configure_flight_reporting: 'Configure Flight Reporting',
    configure_flight_departed: 'Configure Flight Departed',
    password_copy_alert: 'Please copy the password and send it to the user. After saving this user, you cannot copy the password anymore.',

    // permissions
    route_access_not_allowed: 'You do not have the necessary permissions to access this page.',
    route_access_not_allowed_redirected: 'You do not have the necessary permissions to access the page you tried to navigate to',
    flight_update_not_allowed: 'You do not have the necessary permissions to update this flight.', // route management
    path: 'Flight Path',
    assigned_to: 'Assigned To',
    add_assignment: 'Add assignment',
    remove_assignment: 'Remove assignment',
    temporary_assignments: 'Temporary Assignments',
    main_competitor: 'Main Competitor',
    overbooking_bandwidth: 'Overbooking Bandwidth',
    direction: 'Direction',
    time_window: 'Competitor Fares Time Window',
    effected_routes: 'Routes that will be effected from this action are',
    select_users: 'Select Users',
    select_groups: 'Select Groups',
    select_currency: 'Select Fare Currency',
    select_direction: 'Select Direction',
    route: 'route | routes',
    adding: 'Adding',
    removing: 'Removing',
    continuous_pricing_manager: 'Continuous Pricing Manager',
    fare_currency: 'Fare Currency',
    fare_currency_type: 'Fare Currency Type',
    route_groups: 'Route Groups',
    currency_change_loading: 'Changing Currency...',
    direction_change_loading: 'Changing Direction...',
    clusters: 'Clusters',
    pacing_curves: 'Pacing Curves',

    origin_filter: 'Filter on Origin',
    destination_filter: 'Filter on Destination',
    assignee_filter: 'Filter on Assignee',
    currency_filter: 'Filter on Fare Currency',
    direction_filter: 'Filter on Direction',
    route_group_filter: 'Filter on Route Group',
    carrier_filter: 'Filter on Carrier',
    edit_routes: 'Edit Routes...',
    user_assignment: 'User Assignment',
    assign_user: 'Assign User',
    unassign_user: 'Unassign User',
    route_group_assignment: 'Route Group Assignment',
    assign_group: 'Assign Group',
    unassign_group: 'Unassign Group',
    set_cf_time_window: 'Set Competitor Fares Time Window',
    set_fare_currency: 'Set Fare Currency',
    set_direction: 'Set Direction',
    set_clusters: 'Set Clusters',
    define_price_ranges: 'Define Price Ranges',

    // control filters
    origin_destination: 'Origin - Destination',
    hub_path: 'Hub - Flight Path',
    origin_destination_hub: 'O&D / Hub',
    departure_date_range: 'Departure Date Range',
    observation_date_range: 'Observation Date Range',
    users: 'Assigned To',
    aircraft_type: 'Equipment Type',
    // tslint:disable-next-line: quotemark
    default_control_filter: "Departure date range is the default filter and can't be removed.", // control quick class view
    go_to_details: 'Go to Flight Details',
    class_structure_of: 'Preview of {flightLine}',
    protection: 'Protection',
    au: 'Authorization Units',
    sa: 'Seat Availability',
    rsa: 'Recommended Seat Availability',
    rsa_difference: 'Recommended Seat Availability Variance',
    captured_seat_availability: 'Captured Seat Availability',
    min_ctrl: 'Min Threshold',
    add_filter: 'Add Filter',
    add_crossfilter: 'Add Crossfilter',
    flight_number_range: 'Flight Numbers',
    disc_date: 'Discontinue Date',
    eff_date: 'Effective Date',
    flight_number_range_placeholder: 'e.g. 1-5, 8, 110-200',
    reporting_scheduler_day_range_placeholder: 'e.g. 1-5, 8, 20-31',
    number_range_invalid_message: 'This is not a valid range',
    pickup: 'Pickup',
    bookings_pickup_days: '({days}d) Bookings Pickup',
    bookings_pickup_days_short: 'Δ({days}d) Bk',
    revenue_pickup_days: '({days}d) Revenue Pickup',
    performance_band_pickup_days: '({days}d) Performance Band Pickup',
    performance_band_pickup_days_short: 'Δ({days}d) Perf Band',
    cancellations: 'Cancellations',
    discrete: 'Discrete',
    search_cancelled: 'The search has been cancelled',
    clear: 'Clear',

    // bulk actions
    add_an_action: 'Add an action',
    bulk_actions_empty_state_description:
      'You can use mass apply to apply one or more actions to a large group of flights. The actions are executed sequentially',
    bulk_actions_empty_state_title: 'Mass apply one or more actions',

    // inventory grid actions
    apply_recommended_short: 'Apply r{recommended}',
    apply_recommended: 'Apply Recommended {recommended}s',

    overbooking_factor_short: 'OVB Factor',
    overbooking_factor: 'Overbooking Factor',
    optimisation_actions: 'Optimization',
    updated_authorized_capacity: 'Authorized Capacity',
    leftover_capacity_short: 'Leftovers',
    leftover_capacity: 'Leftover Capacity',
    balanced_adjustment: 'Balanced Adjustment',
    updated_balanced_adjustment: 'Balanced Adjustment',
    unbalanced_adjustment: 'Unbalanced Adjustment',
    updated_unbalanced_adjustment: 'Unbalanced Adjustment',

    resolve: 'Resolve',
    resolve_to_ovb: 'Adjust OVB Factor to Match',
    resolve_to_unbalancedadjustment: 'Move to unadjusted balance',
    ignore_leftover: 'Ignore the Leftover Capacity',
    resolve_success: 'The resolve action will be effective after you click apply and then save',

    give_to: 'Give to',
    take_from: 'Take from',
    /* date picker options */
    prev_seven_days: 'Search for the same flight in the previous 7 days',
    next_seven_days: 'Search for the same flight in the next 7 days',
    prev_week: 'Go to same day previous week',
    next_week: 'Go to same day next week',
    departure_date_minus_date: 'dDate - {d}',
    departure_month_short: 'dMonth',
    departure_year_short: 'dYear', // pin
    pin: 'Pin',
    pin_methodology: 'Pin resulting {methodology}',
    unpin_methodology: 'Unpin {methodology}',
    pins: 'Pins',
    pin_count_column_header: 'Pin#',
    pins_count: 'Pins Count',
    unpin: 'Unpin',
    unchanged: 'Unchanged',
    modify_pins: 'Modify Pins',
    is_protection_pinned: 'Protection Pinned',
    is_authorization_unit_pinned: 'AU Pinned',
    updated_bookings_fetching_error:
      'There is a problem with the PSS servers. ' +
      'We are unable to fetch the real-time ' +
      'bookings at the moment. Please try again later.',
    from_duration_to_duration: `{0} to {1}`,
    amount_of_pins: 'Amount of Pins',
    filter: 'Filter',

    not_available: 'N/A',

    filters: 'Filters',
    name: 'Name',
    no_data_to_display: 'No data to display', // cabin class lock
    edit_cabin_class_prompt: 'Are you sure you would like to edit the cabin class {selectedInventoryManagementMethodology}?',
    exclude_zeros: 'Exclude zero values',
    exclude_empty: 'Exclude N/A values',
    clear_selection: 'Clear Selection',

    min_cf: 'Minimum Competitor Fare',
    min_cf_short: 'Min CF',
    min_cf_carrier_code: '({carrier}) Min Competitor Fare',
    cf_currency: 'Competitor Fare Currency',
    min_cf_difference_short: '∆ CF',
    min_cf_difference: '$ Difference to Min CF',
    min_cf_difference_percentage_short: '∆ CF %',
    min_cf_difference_percentage: '% Difference to Min CF',
    min_rival_fare: 'Min Rival Fare',
    min_rival_fare_short: 'Min RF',
    min_rf: 'Minimum Rival Fare',
    min_rf_short: 'Min RF',
    min_rival_fare_carrier_code: '({carrier}) Min Rival Fare',
    min_rival_fare_difference_short: '∆ RF',
    min_rival_fare_difference: '$ Difference to Min RF',
    min_rf_difference_percentage_short: '∆ RF %',
    min_rf_difference_percentage: '% Difference to Min RF',
    rival_fare_short: 'RF',
    currency: 'Currency',

    general_error_title: 'An error occurred',
    general_error_description: 'Something went wrong while loading your data',
    under_construction_empty_state_title: 'Under construction',
    under_construction_empty_state_description: 'This page is under construction and will be available again soon!',
    under_construction_section_empty_state_description: 'This section is under construction and will be available soon!',

    latest_changes: 'Latest changes',
    current_bookings: 'Current bookings',
    unavailable_protection: 'Unavailable protection',
    bk_sa_status: 'Bookings / Seat Availability Status',
    bk_sa_status_short: 'Bk/SA Status',
    cabin_selection: 'Cabin Selection',
    booking_curve: 'Booking Curve',
    no_flight_booking_history_found: 'No flight booking history found',
    no_source_flight_booking_history_found: 'No source flight booking history found',
    last_x_days: 'Last {days} Days',
    ndo: 'NDO',
    error_loading_google_flights: 'Something went wrong when trying to compare the flight online',
    compare_online: 'Compare Online',
    competitors: 'Competitors',
    show_class_distribution: 'Show Class Distribution',

    // REPORTING
    reporting_empty_state_title: "You haven't made any reports yet",
    reporting_empty_state_description: 'Create reports to easily extract data you want, and analyse data in external tools.',
    create_report: 'Create New Report',
    create: 'Create',
    created_by: 'Created By',
    fixed: 'Fixed',
    rolling: 'Rolling',
    add_remove_dimensions: 'Add / Remove Dimensions',
    select_dimensions: 'Select Dimensions',
    available_dimensions: 'Available Dimensions',
    dimensions_in_report: 'Dimensions in Report',
    reporting_dialog_subtitle: 'Drag a value to the right to add it, or to the left to remove it.',
    dimensions: 'Dimensions',
    choose_action: 'Choose action..',

    add_remove_measures: 'Add / Remove Measures',
    select_measures: 'Select Measures',
    available_measures: 'Available Measures',
    measures_in_report: 'Measures in Report',
    measures: 'Measures',
    updated_au: 'Updated Au',
    updated_protection: 'Updated Protection',
    columns: 'Columns',
    total_protection: 'Total protection',
    available_protection: 'Available protection',
    my_reports: 'My Reports',
    shared_reports: 'Shared Reports',
    other_reports: 'Other Reports',
    templates: 'Templates',
    modified: 'Modified',
    last_run: 'Last Run',
    schedule: 'Schedule',
    scheduled: 'Scheduled',
    daily: 'Daily',
    monthly: 'Monthly',
    days_of_week: 'Days of Week',
    days_of_month: 'Days of Month',
    days_of_month_range: 'Days of Month Range',
    add_schedule_days_range: 'Add Range',
    times: 'Times',
    range: 'Range',
    deliver_to: 'Deliver to',
    edit_report: 'Edit',
    copy_report: 'Copy',
    sorting: 'Sorting',
    filtering: 'Filtering',
    schedule_emails_empty_error_msg: 'A scheduled report must have at least one email to deliver to',

    report_extract_cancelled: 'The report extract has been cancelled',
    check_all: 'Check all',
    export_as: 'Export as',
    csv: 'CSV',
    sum: 'SUM',
    count: 'COUNT',
    distance: 'Distance',
    distance_in_kilometers: 'Distance (km)',
    distance_in_miles: 'Distance (mi)',
    by_month: 'By month',
    by_week: 'By week',
    by_day: 'By day',
    departure_day_of_week: 'Departure day of week',
    number_of_stops: 'Number of stops',
    stopovers: 'Stopovers',
    operating_carrier: 'Operating Carrier',
    last_booking_date: 'Last Bk',
    last_booking: 'Last Booking',
    last_captured_booking_date: 'Last cBk',
    last_captured_booking_date_description: 'Last Captured Booking',
    last_booking_explained: 'Days Since Last Booking',
    number_of_flights: 'Number of Flights',

    equals: 'Equals',
    greater_than: 'Greater than',
    greater_than_or_equals: 'Greater than or equals',
    less_than: 'Less than',
    less_than_or_equals: 'Less than or equals',
    n_days_ago: '{count} day ago | {count} days ago',
    n_days: '{n} day | {n} days ',
    unit: 'Unit',
    pin_state: 'Pin state',
    pin_all: 'Pin all',
    clear_changes: 'Clear all',
    unpin_all: 'Unpin all',
    no_change: 'No change',
    percent_symbol: '%',
    n_percent: '{n}%',
    or_list: '{pre}, or {post}',

    final_bookings: 'Final Bookings',
    no_shows: 'No Shows',
    group_no_shows: 'Group No Shows',
    go_shows: 'Go Shows',
    pin_count: 'Pin Count',
    boarded_revenue: 'Boarded Revenue',
    boarded_revenue_short: 'Brd Rev',

    // Move LAF action options
    closest: 'Closest',
    one_class_higher: 'Closest yet higher',
    one_class_lower: 'Closest yet lower',
    set_laf: 'Set LAF',
    action_move_laf_down: "If this action moves a flight's LAF down:",
    set_seats_and_distribution: 'Set available seats to {seats} and distribution to {distribution}.',
    lowest_of_specific_carrier: 'Lowest of specific carrier',
    absolute: 'Absolute',
    relative: 'Relative',
    with_a: 'With a',
    with_an: 'With an',
    price_adjustment_type_of: 'price adjustment type of',
    example_when: 'Example: When ',
    example_is: ' is ',
    example_select_laf: 'Select the LAF that is ',
    example_target_published_fare_price: 'the target published fare price of ',

    // enum BulkActionType
    update_authorization_units: 'Update Authorization Units',
    update_seat_availability: 'Update Seat Availability',
    update_protection: 'Update Protection',
    add_linked_class_rule: 'Add Linked Class Rule',
    remove_linked_class_rule: 'Remove Linked Class Rule',
    apply_linked_class_rule: 'Apply Linked Class Rule',
    add_rival_rule: 'Add Rival Rule',
    remove_rival_rule: 'Remove Rival Rule',
    set_lowest_available_fare_to_match_competitor: 'Set LAF to match Competitor',
    // From user's perspective the above and below types are the same action
    set_lowest_available_fare_to_match_competitor_fares_provided: 'Set LAF to match Competitor',

    // enum BulkActionUpdateType
    au_based: 'Authorization Unit',
    percentage_au_of_class: '% AU of Class',
    percentage_of_scap: '% of sCap',
    percentage_of_acap: '% of aCap',
    sa_based: 'Seat Availability',
    percentage_sa_of_class: '% Sa of Class',
    pr_based: 'Protection',
    percentage_pr_of_class: '% Pr of Class',

    lid_lf_laf: 'Load Factor and LAF Rank (Scale for LAF Is Bottom Class on the Left, Top Class on the Right)',
    lid_lf_laf_short: 'aLF/LAF',

    select_flights: 'Select Flights',
    delete_pacing_curves: 'Delete selected Pacing Curves',
    select_range_tooltip: 'Use Ctrl key to select multiple ranges',
    select_range_shortcut: 'Ctrl+S',

    laf_fare_value: 'Lowest Available Fare Value',
    laf_fare_value_short: 'LAF$',
    recommended_lowest_available_fare_short: 'rLAF',
    active_recommended_lowest_available_fare_short: 'Active rLAF',
    shadow_recommended_lowest_available_fare_short: 'Shadow rLAF',
    recommended_lowest_available_fare_data_science_short: 'Data Science rLAF',
    active_recommended_lowest_available_fare: 'Active Recommended Lowest Available Fare',
    shadow_recommended_lowest_available_fare: 'Shadow Recommended Lowest Available Fare',
    recommended_lowest_available_fare: 'Recommended Lowest Available Fare',
    recommended_lowest_available_fare_data_science: 'Data Science Recommended Lowest Available Fare',
    rlaf_n_class_equal: 'Recommended LAF ({rLaf}) is equal to LAF ({laf})',
    rlaf_n_class_above:
      'Recommended LAF ({rLaf}) is {count} level above LAF ({laf}) | Recommended LAF ({rLaf}) is {count} classes above LAF ({laf})',
    rlaf_n_class_below:
      'Recommended LAF ({rLaf}) is {count} level below LAF ({laf}) | Recommended LAF ({rLaf}) is {count} classes below LAF ({laf})',
    recommended_sa_in_laf_short: 'rSA in LAF',
    recommended_sa_in_laf: 'Recommended Seats Available in rLAF',
    forecasted_load_factor_short: 'FcstLF',
    forecasted_load_factor: 'Forecast Load Factor',
    confidence_score_short: 'Cnf Interval',
    confidence_score: 'Confidence Interval',
    recommended_floor_fare_class_short: 'rFF',
    recommended_floor_fare_class: 'Recommended Floor Fare',
    forecased_average_fare_short: 'Fcst AvgFare',
    forecased_average_fare: 'Forecast Average Fare',
    comments: 'Comments',
    data_science_comments_description: 'Data Science Comments',

    min_fare_of_carrier_short: '{carrier} Min CF',
    min_fare_of_carrier: 'Minimum Competitor Fare',
    delta_min_fare_of_carrier_short: '{carrier} Δ CF',
    delta_min_fare_of_carrier: '$ Difference to Own Fare',
    delta_percentage_min_fare_of_carrier_short: '{carrier} Δ CF %',
    delta_percentage_min_fare_of_carrier: '% Difference to Own Fare',

    // nego space
    nego_space: 'Nego',
    tour_operator_name: 'Tour Operator Name',
    contract_code: 'Contract Code',
    seats: 'Seats',
    x_available: '{x} available',
    x_of_y: '{x} of {y}',

    apply_r_au_s: "Apply rAU's",
    show: 'Show',
    hide: 'Hide',

    save_flight_action_group: 'Save As a New Quick Action',
    edit_flight_action_group: 'Edit the existing quick action',
    flight_actions_description: 'Save your current actions as a quick action to apply changes quickly from Control.',
    flight_actions_edit_description: 'Save your changes to the existing quick action.',
    save_as_quick_action: 'Save As a New Quick Action',
    save_changes: 'Save changes..',
    edit_quick_action: 'Edit Quick Action',
    quick_action_deleted: 'Quick action is deleted.',
    row_shading: 'Row Shading',
    row_shading_description:
      'While sorting, this setting will group similar results by the same color. The grouping is based on the selected value',
    control_group_setting: {
      none: 'None',
      origin: 'Org',
      origin_plus_destination: 'Org + Des',
      origin_plus_departure_date: 'Org + dDate',
      origin_plus_dow: 'Org + DOW',
      origin_plus_destination_plus_departure_date: 'Org + Des + dDate',
      origin_plus_destination_plus_dow: 'Org + Des + DOW',
      departure_date: 'dDate',
      dow: 'DOW',
    },
    more: 'More',

    route_price_range: 'Route Price Range',
    class_code: 'Class Code',
    minimum_price: 'Minimum Price',
    minimum_short: 'Min',
    maximum_short: 'Max',
    average: 'Average',

    pricing_adjustment: 'Pricing Adjustment',
    price_increment: 'Price Increment',
    are_you_sure_apply_continuous_pricing_for_all_selected_routes:
      'Are you sure you want to apply the Route Price Range to all {routesCount} selected routes?',
    proceed: 'Proceed',
    continuous_pricing_notification:
      "There's no pricing structure set for this flight. You can do it now by clicking here or later in the Route Management Settings.",
    save_to_all: 'Save to all',
    applying_route_price_range_to_all_selected_routes: 'Applying the Route Price Range to all selected routes',
    all_routes_have_been_updated: 'All routes have been updated',
    clear_crossfilter_selections: 'Reset',
    pinned_classes_short: 'Pinned Cls',
    pinned_classes: 'Pinned Classes',

    monday_short: 'Mon',
    tuesday_short: 'Tue',
    wednesday_short: 'Wed',
    thursday_short: 'Thu',
    friday_short: 'Fri',
    saturday_short: 'Sat',
    sunday_short: 'Sun',
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    shortDaysMap: {
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
    },
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days_since_last_user_activity: 'Days since last User Activity',
    import_tags: 'Import tags',
    select_files: 'Select File(s)',
    validate: 'Validate',
    import: 'Import',
    import_completed: 'Import completed',
    select_more_files: 'Select more files',
    drop_files_here: 'Drop your .csv file(s) here or click here to upload.',
    csv_files_max_10mb: 'The file size must be less than 10MB.',
    validation_failed: 'Validation failed',
    parsing_failed: 'Parsing failed',
    columns_couldnt_get_parsed: "Some columns in the file couldn't get parsed",
    parsing_failed_message:
      "Some fields in the document couldn't be parsed, please check if the following columns in the file are present and properly named",
    more_info: 'More Info',
    download_template: 'Download Template',
    start_import: 'Start Import',
    tag_name: 'Tag Name',
    reason: 'Reason',
    line_number: 'Line Number',
    specific_error: 'Specific Error',
    line_content: 'Line Content',
    tag_assignments: 'Tag Assignments',
    tag_assignments_description: 'Assign new and existing tags to flights.',
    no_import: 'No Import',
    flight_not_found: 'Flight not found',
    tag_not_found: 'Tag not found',
    profile_cabin_not_found: 'Profile Cabin not found',
    flight_cabin_not_found: 'Flight Cabin not found',
    profile_not_found: 'Profile not found',
    profile_level_not_found: 'Profile Level not found',
    cluster_not_found: 'Cluster not found',
    cluster_cabin_not_found: 'Cluster Cabin not found',
    assigned_clusters_and_pacing_curves: 'Assigned clusters and pacing curves',
    cluster_not_assigned_to_cabin: 'Cluster not assigned to Cabin',
    optimization_profile_level_assignments: 'Optimization Profile Level Assignments',
    optimization_profile_level_assignments_description: 'Assign existing optimization profile levels to flights.',
    source_flight_assignments: 'Source Flight Assignments',
    source_flight_assignments_description: 'Assign source flights to flights.',
    file_size_exceeded: 'Maximum file size ({maximum}MB) exceeded, please upload a smaller file.',
    pacing_curve: 'Pacing Curve',
    pacing_curves_import_description: 'Create new pacing curves or update existing ones.',
    update: 'Update',
    number_of_data_points: 'Number of Data Points',
    negative_number: 'Negative Number present',
    invalid_range: 'Invalid Range, check that there are no duplicate data points',
    missing_ndo_0: 'Missing a data point with an NDO of 0',
    missing_lf_0: 'Missing a data point with a LF of 0',
    name_over_max_length: 'Name is too long (max 5 characters)',
    exceeds_max_ndo_points: "Can't add more than 100 NDO points",
    exceeds_max_lf: "Can't add Load factor higher than 1",
    exceeds_max_tolerance_level: "Can't add Tolerance level higher than 0.5",
    cluster_assignments: 'Cluster Assignments',
    cluster_assignments_description: 'Assign existing clusters to flights.',
    tolerance_level: 'Tolerance Level',
    add_pacing_curve: 'Add Pacing Curve',
    user_created: 'User created',
    created: 'Created',
    modified_by: 'Modified By',
    save: 'Save',
    data_points: 'Data Points',
    add_data_point: 'Add Data Point',
    loading_pacing_curves: 'Loading Pacing Curves...',
    unique_pacing_curve_name_needed: 'Pacing curve should have a unique name',
    pacing_curves_empty_state_title: "You haven't made any pacing curves yet",
    pacing_curves_empty_state_description: 'Create pacing curves to easily be able to see how your flights are performing.',
    create_pacing_curve: 'Create new pacing curve',
    display_name_length: 'Max 10 Characters',
    expected_bookings: 'Expected Bookings',
    expected_bookings_short: 'eBk',
    expected_load_factor: 'Expected Load Factor',
    expected_load_factor_short: 'eLF',
    performance_band: 'Performance Band',
    cluster: 'Cluster',
    cluster_id: 'Cluster Id',
    minimum_name_length: 'Please make sure the name contains at least one character.',
    maximum_name_length: 'Please make sure the name contains less than ten characters.',
    name_already_exists: 'This name already exists, please choose a different one.',
    cluster_assignment_description:
      'For each Cabin, assign the Clusters that should be set for the selected routes, and then assign a Pacing Curve to each Cluster. Clusters are evaluated in numerical order. To change the order of a Cluster, click and drag it vertically.',
    select_season_placeholder: 'Select a Season...',
    continuous_pricing: 'Continuous Pricing',
    save_pricing_changes: 'Save Pricing Changes',
    continuous_pricing_description:
      'To add or update the pricing for the selected route, please first select a season that you want the prices to be applied.',
    duplicate_route_cluster_assignment:
      'It is not possible to assign the same cluster multiple times. Please make sure the cluster assignments do not have duplicate clusters.',
    no_assignments: 'No Assignments',
    performance_score: 'Performance Score',
    pacing_performance_score_short: 'Perf Score',
    pacing_performance_score: 'Pacing Performance Score',
    pacing_performance_band: 'Pacing Performance Band',
    pacing_performance_band_short: 'Perf Band',
    cluster_level: 'Cluster Level',
    final_revenue: 'Final Revenue',
    copy: 'Copy',
    new_report_name: 'A new report by {name} ({number})',
    carrier_code: 'Carrier Code',
    description: 'Description',
    new_pacing_curve: 'New Pacing Curve',
    load_factor_from_until_to: 'LF {from} to {to}',
    avg: 'Avg',
    max: 'Max',
    min: 'Min',
    cnt: 'Cnt',
    var: 'Var',
    std: 'Std',
    text: 'Text',
    list: 'List',
    group_bookings: 'Group Bookings',
    group_pending: 'Group Pending',
    group_waitlist: 'Group Waitlist',
    waitlist: 'Waitlist',
    pinned_au: 'Pinned Au',
    drop_out: 'Drop Out',
    loading: 'Loading...',
    settings_separator_title: 'Separator for exports',
    settings_separator_description: 'The separator used when extracting a Report',
    route_key: 'Route Key',
    flight_key: 'Flight Key',
    source_saleable_capacity: 'Source Saleable Capacity',
    source_authorized_capacity: 'Source Authorized Capacity',
    source_boarded_revenue: 'Source Boarded Revenue',

    ndo_bookings: 'NDO Bookings',
    ndo_bookings_days: 'NDO ({days}d) Bookings',

    ndo_revenues: 'NDO Revenue',
    ndo_revenues_days: 'NDO ({days}d) Revenues',

    revert_to_last_capture: 'Revert to Nightly Capture',
    close_lowest_available_fare: 'Move LAF Up',
    set_au_below_laf_to_zero: 'Set AU Below LAF to 0',
    open_and_set_seats_lowest_available_fare: 'Move LAF Down',
    increase_seats_lowest_available_fare: 'Increase Availability of LAF',
    set_seats_lowest_available_fare: 'Set Availability in LAF',
    add_optimisation_profile_level: 'Add Active Optimization Tactic',
    remove_optimisation_profile_level: 'Remove Active Optimization Tactic',
    swap_optimisation_tactic: 'Swap Optimization Tactics',
    add_shadow_optimisation_profile_level: 'Add Shadow Optimization Tactic',
    remove_shadow_optimisation_profile_level: 'Remove Shadow Optimization Tactic',
    change_overbooking_factor: 'Set OVB factor',
    change_multi_leg_overbooking_factor: 'Set OVB factor',
    set_recommended_overbooking_risk_percentage: 'Set rOVB Risk %',
    set_multi_legged_recommended_overbooking_risk_percentage: 'Set rOVB Risk %',
    update_multi_legged_overbooking_autopilot: 'Set OVB Autopilot',
    update_overbooking_autopilot: 'Set OVB Autopilot',
    apply_recommended_overbooking_factor: 'Apply rOVB',
    apply_recommended_au: 'Apply rAU',
    move_recommended_overbooking_risk_percentage: 'Move rOVB Risk % Level',
    apply_datascience_recommended_laf: 'Apply rLAF',
    change_unbalanced_adjustment: 'Set Unbalanced Adjustment',
    change_multi_leg_unbalanced_adjustment: 'Set Unbalanced Adjustment',
    change_balanced_adjustment: 'Set Balanced Adjustment',
    change_multi_leg_balanced_adjustment: 'Set Balanced Adjusment',
    set_pricing_adjustment: 'Set Tactical Adjustment',
    move_tactical_adjustment_level: 'Move Tac Adj',
    set_pricing_increment: 'Set Price Increment',
    set_pricing_tactic: 'Set Pricing Tactic',
    resolve_leftover_capacity_by_ignoring: 'Resolve Leftover Capacity (Remove Leftover)',
    resolve_leftover_capacity_to_ovb: 'Resolve Leftover Capacity (use OVB Factor)',
    move_optimisation_profile_level: 'Move Active OP Level',
    move_shadow_optimisation_profile_level: 'Move Shadow OP Level',
    correct_au_structure: 'Correct AU Structure',
    update_min_control: 'Set Min Threshold',
    update_segment_limit: 'Set Segment Limit',
    update_effective_date: 'Set Effective Date',
    update_discontinued_date: 'Set Discontinued Date',
    update_autopilot: 'Set Autopilot',
    remove_tags: 'Remove Tags',
    remove_all_tags: 'Remove All Tags',
    move_cluster_level: 'Move Cluster Level',
    update_cluster_level: 'Set Cluster Level',
    update_source_flight: 'Set Source Flight',

    field: 'Field',
    before: 'Before',
    after: 'After',
    cabin_changes: 'Cabin Changes',
    class_changes: 'Class Changes',
    flight_changes: 'Flight Changes',
    general_changes: 'General Changes',
    others: 'other(s)',

    flight_fields: {
      // Flight
      source_flight_id: 'Source Flight',
      tags: 'Tags',
      linked_class_rule_id: 'Linked Class Rule',
      rival_rule_id: 'Rival Rule',

      // Cabin
      autopilot: 'Autopilot',
      optimisation_tactics: 'Active Opt Tactic',
      optimisation_profile_level: 'Active Profile Level',
      shadow_optimisation_tactics: 'Shadow Opt Tactic',
      shadow_optimisation_profile_level: 'Shadow Profile Level',
      cluster_id: 'Cluster',
      pricing_adjustment: 'Tac Adj',
      pricing_tactic: 'Pricing Tac',
      lowest_available_fare_class: 'LAF',
      performance_score: 'Perf Score',

      // Class
      flight_id: 'Flight Id',
      cabin_code: 'Cabin Code',
      class_code: 'Class Code',
      fare_value: 'Fare Value',
      effective_date: 'Effe Date',
      discontinued_date: 'Disc Date',
      segment_limit: 'SL',
      min_control: 'Min Control',
      authorization_units: 'AU',
      protection: 'PR',
      min_protection: 'Min',
      max_protection: 'Max',
      is_authorization_unit_pinned: 'Pinned',
      is_protection_pinned: 'Pinned',
      promotion: 'Promotion',

      // Leg Cabin
      overbooking_factor: 'OVB',
      authorized_capacity: 'aCap',
      capacity_adjustment: 'Capacity Adjustment',
      unbalanced_adjustment: 'Unbalanced Adjustment',
      balanced_adjustment: 'Balanced Adjustment',
      maxi_adjustment: 'Maxi Adjustment',
      overbooking_risk: 'Overbooking Risk',
      recommended_overbooking_factor: 'Recommended Overbooking Factor',
    },
    imported_new_flight: 'Imported new Flight',
    optimization_profile: 'Optimization Profile',
    optimization_tactic: 'Optimization Tactic',
    details_changes: 'Details changes',
    bulk_changes: 'Bulk changes',

    profile: 'Profile',
    unknown: 'Unknown',

    // Promotion Management
    promotions_empty_state_title: "You haven't made any promotions yet",
    promotions_empty_state_description: 'Create promotions to create campaigns to boost your flights.',
    create_promotion: 'Create new promotion',
    add_promotion: 'Add promotion',
    promotions_management: 'Promotions Management',

    fixed_prices: 'Fixed Prices',
    dynamic_prices: 'Dynamic Prices',
    pricing_tactics: 'Pricing Tactics',
    price: 'Price',
    add_price: 'Add Price',

    promo_start: 'Promo Start',
    promo_end: 'Promo End',
    promo_type: 'Promo Type',
    departure_start: 'Departure Start',
    departure_end: 'Departure End',
    state: 'State',
    created_on: 'Created On',

    loading_promotions: 'Loading Promotions...',
    add_another_range: 'Add another range',

    promotion_prompt: 'Please input a unique name for the promotion (maximum 5 characters).',
    promotion_prompt_header: 'Copy Promotion',
    promotion_prompt_error_message: 'The name must be maximum 5 characters long.',
    unique_promotion_name_needed: 'Promotion should have a unique name',

    planned: 'Planned',
    ended: 'Ended',

    date_ranges: 'Date Ranges',
    promotion: 'Promotion',
    departure_date_ranges_empty_error_message: 'At least one departure date range must be specified',
    departure_date_ranges__overlap_error_message: 'Date Ranges overlap, click here to go to editor for Promotion',
    promotion_departure_date_range_are_the_same: "Departure date ranges can't have the same start and end date",
    set_promotion: 'Set Promotion',
    remove_promotion: 'Remove Promotion',
    promotions_short: 'Promos',
    assigned_promotion_short: 'Assigned Promo',
    assigned_promotion: 'Assigned Promotion',
    promotion_state: 'Promotion is {state}',
    promotion_level_price: 'Promotion Level - Price',
    promotion_level_price_short: 'Promo Level - Price',

    promotion_update_confirm_changes: 'Eddy will remove any assigned promotions that fall within the updated departure date range',

    max_characters: 'Max 10 characters',

    // Authentication
    // change Password view
    change_password: 'Change your password',
    fill_password_twice: 'Please fill in your new password below twice, to change your password.',
    reset: 'Reset',
    back_to_login: 'Back to Login',
    password_has_been_reset: 'Your password has been reset, you can now login.',
    go_to_login: 'Go to Login',
    contains_a_lowercase_character: 'Contains a lowercase character',
    contains_an_uppercase_character: 'Contains an uppercase character',

    // Reset Password Link Expired view
    link_has_expired: 'Your link has expired',
    request_new_link: 'The link to reset your password has expired. Please request a new one by going to the reset password page',
    reset_password_again: 'Reset password again',
    minimum_of_x_characters: 'A minimum of {count} character | A minimum of {count} characters',
    contains_a_digit: 'Contains a digit',
    contains_a_symbol: 'Contains a symbol',
    contains_no_parts_of_your_username: 'Contains no parts of your username', // Verify Email view
    email_is_verified: 'Email is verified',
    verify_email: 'Please verify your email address',
    verify_email_before_continuing: 'To provide the best security, it is required to verify your email before continuing.',
    passwords_are_equal: 'Passwords are equal',
    passwords_are_not_equal: 'Passwords are not equal',

    // Forgot Password view
    forgot_your_password: 'Forgot your password?',
    enter_email_below: "It happens to the best of us. Please enter your email below, and we'll send you a link.",
    check_inbox: "We've sent you an email containing a link to reset your password, please check your inbox.",

    // Login
    auth_equal_passwords: 'New and old passwords are the same',
    auth_too_many_requests: 'Too many failed login attempts, account has been temporarily disabled. Please try again later',
    auth_wrong_current_password: 'Current password is incorrect',
    almost_signed_in: 'A few more clicks to{0} sign in to your account',

    manage_flights: 'Manage all your flights in one place.',

    password_copied: 'Password copied to clipboard.',

    enroll_into_mfa: 'Enroll into MFA',
    contact_support_to_unenroll_user:
      'To remove the 2 factor authentication for this user please contact support by emailing <a class="text-lightBlue" href="mailto:kambr.support@kambr.com">kambr.support@kambr.com</a>',
    remove_cluster: 'Remove Cluster',
    remove_source_flight: 'Remove Source Flight',
    published_fares_import: 'Published Fares',
    published_fares_import_description: 'Import base and/or published fare values to flights.',
    fares_to_import: 'Fares to import',
    fares_to_delete: 'Fares to delete',
    base_fare: 'Base Fare',
    published_fare_short: 'Pub Fare',
    published_fare: 'Published Fare',
    start_departure_date: 'Start Departure Date',
    end_departure_date: 'End Departure Date',
    start_sale_date: 'Start Sale Date',
    end_sale_date: 'End Sale Date',
    warning_saving_minimum_price: `Saving Minimum Price changes will result in the Authorization Units
    (AU) on all flights for the selected Route/Season to be reset to the
    captured AU values. Any classes that have been pinned (pinned AUs)
    will have the pins removed and the AUs also reset to the captured AU
    values. Please be aware of these changes when making changes to the
    Minimum Prices.`,
  },
};
