<template>
  <div>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { Constants } from '@/constants';
import { RouteName } from '@/modules/shared';
import { FlightService } from '@/services/flight.service';
import { AuthModule } from '@/store/modules/auth.module';
import { FlightModule } from '@/store/modules/flight.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

export default defineComponent({
  name: 'App',
  computed: {
    fontSize(): string {
      return `${UserConfigModule?.fontSize ?? Constants.DEFAULT_FONT_SIZE}px`;
    },
  },
  async created() {
    // TODO: This shouldn't be on app level,
    // It should move to the page that is blocking navigation
    window.addEventListener('beforeunload', (event) => {
      if (FlightService.hasUnsavedChanges(FlightModule.flightActions)) {
        event.preventDefault();
        event.returnValue = '';
      }
    });

    if (!AuthModule.token && !window.location.pathname.startsWith('/external')) {
      this.$router.push({
        name: RouteName.login,
        query: {
          redirect: window.location.pathname + window.location.search,
        },
      });
    }
  },
});
</script>

<style lang="scss">
// Import all application wide styles
@import '@/styles/index.scss';

// Override the font size for all grids
:root,
.ag-theme-alpine {
  --ag-font-size: v-bind(fontSize);
}
</style>
