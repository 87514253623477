<template>
  <div>
    <sticky-header>
      <template #title>
        <k-bread-crumb :crumbs="breadcrumbs" />
      </template>

      <template v-if="canCreateNewLinkedClassRule" #menu>
        <el-button v-if="!isLoadingLinkedClassRule && !isNew" plain @click="revertChanges">
          {{ t('actions.revert_changes') }}
        </el-button>

        <k-save-button-group
          class="ml-2"
          :loading="isLoadingLinkedClassRule"
          :disabled="isLoadingLinkedClassRule"
          @save="onSave(false)"
          @save-and-close="onSave(true)"
        />
      </template>
    </sticky-header>

    <el-row v-loading="isLoadingLinkedClassRule" :element-loading-text="`${t('settings.loading')}...`" :gutter="20">
      <el-col :span="8">
        <k-card :title="$t('linked_class_rules.general')">
          <div>
            <div>
              {{ t('linked_class_rules.display_name') }}
            </div>

            <div>
              <el-input
                v-if="linkedClassRule"
                v-model="linkedClassRule.name"
                :placeholder="t('linked_class_rules.display_name_placeholder')"
                :minlength="5"
                :maxlength="20"
                data-test="linked-class-name-input"
              />
            </div>
          </div>
          <div class="pt-2">
            <div>
              {{ t('description') }}
            </div>

            <div>
              <el-input
                v-if="linkedClassRule"
                v-model="linkedClassRule.description"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                :placeholder="t('description')"
                :maxlength="450"
              />
            </div>
          </div>
        </k-card>
      </el-col>

      <el-col :span="13">
        <k-card :title="$t('linked_class_rules.linked_classes')">
          <linked-class-rule-detail-list
            v-if="linkedClassRule && linkedClassRule.details.length > 0"
            :rules="linkedClassRule.details"
            @update:rules="onRulesUpdate"
          />

          <k-button
            v-if="canCreateNewLinkedClassRule"
            icon="plus"
            variant="outline-green"
            data-test="linked-class-add-rule-button"
            @click="addNewLinkedClassRule"
          >
            {{ t('linked_class_rules.add_linked_class_rule') }}
          </k-button>
        </k-card>
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { NavigationGuardNext, RouteLocationNormalized, useRouter } from 'vue-router';

import { Authority } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import LinkedClassRuleDetailList from '@/modules/rules/linked-class-rules/components/LinkedClassRuleDetailList.vue';
import { useLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { LinkedClassRuleDetailModel } from '@/modules/rules/linked-class-rules/models/linked-class-rule.model';
import { MessageService } from '@/modules/shared';
import KButton from '@/modules/shared/components/buttons/KButton.vue';
import KCard from '@/modules/shared/components/card/KCard.vue';
import KBreadCrumb from '@/modules/shared/components/KBreadCrumb.vue';
import StickyHeader from '@/modules/shared/components/layouts/StickyHeader.vue';
import KSaveButtonGroup from '@/modules/shared/components/save-button-group/KSaveButtonGroup.vue';
import { BreadCrumbInterface, RouteName } from '@/modules/shared/types/routes';
import { MessageBoxService } from '@/services/message-box.service';
import { UserConfigModule } from '@/store/modules/user-config.module';

export default defineComponent({
  components: {
    KBreadCrumb,
    KSaveButtonGroup,
    StickyHeader,
    KCard,
    KButton,
    LinkedClassRuleDetailList,
  },
  async beforeRouteLeave(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (this.hasChanges()) {
      try {
        await MessageBoxService.unsavedChanges();
        next();
      } catch (error) {
        next(false);
      }
    } else {
      next();
    }
  },
  setup() {
    const router = useRouter();

    const {
      isLoadingLinkedClassRule,
      linkedClassRule,
      loadLinkedClassRule,
      revertChanges,
      saveLinkedClassRule,
      hasChanges,
      addNewLinkedClassRule,
      loadNewLinkedClassRule,
      isNew,
    } = useLinkedClassRule();

    const { t } = useI18n();

    const breadcrumbs = computed((): BreadCrumbInterface[] => [
      {
        displayName: t('rules.rules'),
        to: { name: RouteName.rulesOverview },
      },
      {
        displayName: t('linked_class_rules.linked_class_rules'),
        to: { name: RouteName.linkedClassRulesOverview },
      },
      {
        displayName: linkedClassRule.value ? linkedClassRule.value.name : `${t('linked_class_rules.loading')}...`,
        to: { name: RouteName.linkedClassRulesDetails },
      },
    ]);

    const canCreateNewLinkedClassRule = computed(() =>
      authService.hasAuthority([Authority.LinkedClassRulesUpdate], UserConfigModule.user.authorities),
    );

    onMounted(() => {
      if (router.currentRoute.value.name === RouteName.linkedClassRulesCreate) {
        loadNewLinkedClassRule();
      } else {
        const linkedClassRuleId = parseInt(router.currentRoute.value.params.id as string);

        if (isFinite(linkedClassRuleId)) {
          loadLinkedClassRule(linkedClassRuleId, true);
        }
      }
    });

    async function onSave(saveAndClose: boolean) {
      if (!linkedClassRule.value.name) {
        MessageService.error(t('form.validate_name'));

        return;
      }

      const response = await saveLinkedClassRule();

      if (response && saveAndClose) {
        router.push({
          name: RouteName.rulesOverview,
        });
      }
    }

    function onRulesUpdate($event: LinkedClassRuleDetailModel[]) {
      linkedClassRule.value.details = $event;
    }

    return {
      isLoadingLinkedClassRule,
      canCreateNewLinkedClassRule,
      linkedClassRule,
      hasChanges,
      breadcrumbs,
      revertChanges,
      loadNewLinkedClassRule,
      onSave,
      addNewLinkedClassRule,
      isNew,
      onRulesUpdate,
      t,
    };
  },
});
</script>
