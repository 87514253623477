import { RouteRecordRaw } from 'vue-router';

import { Feature } from '@/modules/features';
import { RouteName } from '@/modules/shared/types/routes';

export const DOCS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'docs',
    name: RouteName.docs,
    component: () => import('../views/DocsView.vue'),
    redirect: { name: RouteName.releaseNotes },
    children: [
      {
        path: 'release-notes',
        name: RouteName.releaseNotes,
        meta: {
          features: [Feature.DOC_HUB],
        },
        component: () => import('../views/ReleaseNotesView.vue'),
      },
    ],
  },
];
