export enum Feature {
  KPI_CALCULATIONS = 'kpiCalculations',
  BID_PRICE_OPTIMIZATION = 'bidPriceOptimization',
  GOLD_MODEL_TOGGLE = 'goldModelToggle',
  DOC_HUB = 'docHub',
}

export type FeaturesModel = Partial<Record<Feature, boolean>>;

export type FeaturesType = keyof typeof Feature;

export interface IFeaturesService {
  get(): Promise<FeaturesModel>;
}
