import { isEqual, pullAllWith, unionWith } from 'lodash-es';

import { OpLevelAdjustmentIncrementActionType } from '@/models/enums';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { ApiClient } from '@/modules/api/base-client';
import { RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { SetForecastGenerationAction } from '@/modules/route-management/api/routes/actions/set-forecast-generation-action';
import { SetForecastLearningAction } from '@/modules/route-management/api/routes/actions/set-forecast-learning-action';
import { SlimRouteModel } from '@/modules/route-management/store/route-management.store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';

import { SetCompetitiveFareRangeAction } from './actions/set-competitive-fare-range-action';
import { SetDirectionAction } from './actions/set-direction-action';
import { SetFareCurrencyAction } from './actions/set-fare-currency-action';
import {
  SetOpLevelAdjustmentIncrementAction,
  SetOpLevelAdjustmentIncrementPayload,
  opLevelAdjustmentIncrementDefault,
} from './actions/set-op-level-adjustment-increment-action';
import { IRoutesService, RouteModel, RouteProjection } from './routes.contracts';

export class RoutesService implements IRoutesService {
  private basePath = 'routes';

  public async get(projection?: RouteProjection): Promise<RouteModel[]> {
    let urlString = this.basePath;

    urlString = projection ? urlString + '?projection=' + projection : urlString;

    return (await ApiClient.get<RouteModel[]>(urlString)).map((route) => {
      route.cabins =
        route.cabins.length === 0
          ? AppSettingsModule.inventoryConfigurationProperties.cabins.map((cabin) => ({
              cabinCode: cabin.code,
              byorOpLevelAdjustmentIncrement: opLevelAdjustmentIncrementDefault,
              dsOpLevelAdjustmentIncrement: opLevelAdjustmentIncrementDefault,
            }))
          : route.cabins;

      return route;
    });
  }

  public async getAllRoutes(projection?: RouteProjection): Promise<RouteModel[]> {
    const response = await this.get(projection);
    return this.transformRoutes(response);
  }

  public async setCFWindow(selectedRoutes: SlimRouteModel[], timeWindow: number[]): Promise<RouteModel[]> {
    const competitiveFareStartAt = timeWindow[0] * 60;
    const competitiveFareEndAt = timeWindow[1] * 60;

    const requests: RouteModel[] = [];

    const action = new SetCompetitiveFareRangeAction(competitiveFareStartAt, competitiveFareEndAt);

    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions: [await action.getPayload()],
        }),
      );
    }

    return await Promise.all(requests);
  }

  private transformRoutes(routes: RouteModel[]): RouteModel[] {
    return routes.map((route: RouteModel) => {
      route.airportOriginName = route.airportOrigin ? route.airportOrigin.airportName : '';
      route.airportDestinationName = route.airportDestination ? route.airportDestination.airportName : '';

      return route;
    });
  }

  public async setCurrency(selectedRoutes: SlimRouteModel[], fareCurrency: string): Promise<RouteModel[]> {
    const selectedCurrency = fareCurrency;
    const requests: RouteModel[] = [];

    const action = new SetFareCurrencyAction(selectedCurrency);

    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions: [await action.getPayload()],
        }),
      );
    }

    return await Promise.all(requests);
  }

  public async setForecastGeneration(selectedRoutes: SlimRouteModel[], forecastGeneration: boolean): Promise<void> {
    const requests: RouteModel[] = [];
    const action = new SetForecastGenerationAction(forecastGeneration);
    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions: [await action.getPayload()],
        }),
      );
    }

    await Promise.all(requests);
  }

  public async setForecastLearning(selectedRoutes: SlimRouteModel[], forecastLearning: boolean): Promise<void> {
    const requests: RouteModel[] = [];
    const action = new SetForecastLearningAction(forecastLearning);
    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions: [await action.getPayload()],
        }),
      );
    }

    await Promise.all(requests);
  }

  public async setAssignRouteGroups(selectedRoutes: SlimRouteModel[], routeGroups: RouteGroup[]): Promise<RouteGroup[]> {
    const requests: RouteGroup[] = [];

    for (const selectedRoute of selectedRoutes) {
      const ids = unionWith(selectedRoute.routeGroups, routeGroups, isEqual).map((routeGroup) => routeGroup.id);

      if (ids.length > 0) {
        requests.push(
          await ApiClient.put<RouteGroup>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}/route-groups`, {
            ids,
          }),
        );
      }
    }

    return await Promise.all(requests);
  }

  public async setUnassignRouteGroups(selectedRoutes: SlimRouteModel[], routeGroups: RouteGroup[]): Promise<RouteGroup[]> {
    const requests: RouteGroup[] = [];

    for (const selectedRoute of selectedRoutes) {
      const ids = pullAllWith([...(selectedRoute.routeGroups ?? [])], routeGroups, isEqual).map((routeGroup) => routeGroup.id);

      requests.push(
        await ApiClient.put<RouteGroup>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}/route-groups`, {
          ids,
        }),
      );
    }

    return await Promise.all(requests);
  }

  public async setDirection(selectedRoutes: SlimRouteModel[], direction: string): Promise<RouteModel[]> {
    const requests: RouteModel[] = [];

    const action = new SetDirectionAction(direction);

    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions: [await action.getPayload()],
        }),
      );
    }

    return await Promise.all(requests);
  }

  public async setOpLevelAdjustmentIncrement(
    selectedRoutes: SlimRouteModel[],
    cabinOpLevelAdjustmentIncrements: Map<CabinCode, number>,
    actionType: OpLevelAdjustmentIncrementActionType,
  ): Promise<void> {
    // TODO: for now we need to set the flightPath and carrierCode in the endpoint,
    //  this means we can't do a bulk action for all routes in one go. We'll have to fix that later in #16600
    // https://dev.azure.com/kambr/Eddy/_workitems/edit/16600
    const requests: RouteModel[] = [];
    const actions: SetOpLevelAdjustmentIncrementPayload[] = [];

    cabinOpLevelAdjustmentIncrements.forEach((cabinOpLevelAdjustmentIncrement, cabinCode) => {
      actions.push(new SetOpLevelAdjustmentIncrementAction(cabinCode, cabinOpLevelAdjustmentIncrement, actionType).getPayload());
    });

    for (const selectedRoute of selectedRoutes) {
      requests.push(
        await ApiClient.patch<RouteModel>(`${this.basePath}/${selectedRoute.flightPath}_${selectedRoute.carrierCode}`, {
          routeIds: [selectedRoute.id],
          actions,
        }),
      );
    }

    await Promise.all(requests);
  }
}

export const routesService = new RoutesService();
