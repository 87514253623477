import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';

import { NumberColumnFilterSettings } from '@/models/columns/definitions/base';
import { ColumnId } from '@/models/enums/grid';
import { FlightViewFlightLineRow } from '@/models/FlightModel';
import { i18n } from '@/plugins/i18n';
import { FormatService } from '@/services/format.service';

const { t } = i18n.global;

export const CabinClassSourceFinalGroupBookingsColumn: ColDef = {
  colId: ColumnId.SourceFinalGroupBookings,
  headerName: t('source_fn_grp_bk_short'),
  minWidth: 25,
  width: 25,
  type: 'numericColumn',
  headerTooltip: t('source_fn_grp_bk'),
  hide: true,
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalGroupBookings : null;
  },
};

export const CabinClassSourceGroupBookingsColumn: ColDef = {
  colId: ColumnId.SourceGroupBookings,
  headerName: t('source_grp_bk_short'),
  minWidth: 25,
  width: 25,
  type: 'numericColumn',
  headerTooltip: t('source_grp_bk'),
  hide: true,
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.ndoGroupBookings : null;
  },
};

export const CabinClassSourceGroupBoardingsColumn: ColDef = {
  colId: ColumnId.SourceGroupBoardings,
  headerName: t('source_grp_boa_short'),
  minWidth: 90,
  width: 90,
  type: 'numericColumn',
  headerTooltip: t('source_grp_boa'),
  hide: true,
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalGroupBoardings : null;
  },
};

export const CabinClassSourceBookingsColumn: ColDef = {
  colId: ColumnId.SourceBookings,
  headerName: t('source_bk_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  headerTooltip: t('source_bk'),
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.ndoBookings : null;
  },
};

export const CabinClassSourceFinalBookingsColumn: ColDef = {
  colId: ColumnId.SourceFinalBookings,
  headerName: t('source_fn_bk_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  headerTooltip: t('source_fn_bk'),
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalBookings : null;
  },
};

export const CabinClassSourceBoardingsColumn: ColDef = {
  colId: ColumnId.SourceBoardings,
  headerName: t('source_brds_short'),
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  headerTooltip: t('source_brds'),
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalBoardings : null;
  },
};

export const CabinClassSourceFlightRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightRevenue,
  headerName: t('source_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_rev'),
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (revenue && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(revenue, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.ndoRevenue : null;
  },
};

export const CabinClassSourceFlightBoardedRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightBoardedRevenue,
  headerName: t('source_brd_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_brd_rev'),
  valueFormatter: (params: ValueFormatterParams) => {
    const boardedRevenue = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (boardedRevenue && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(boardedRevenue, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalBoardedRevenue : null;
  },
};

export const CabinClassSourceFlightFinalRevenueColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightFinalRevenue,
  headerName: t('source_fn_rev_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_fn_rev'),
  valueFormatter: (params: ValueFormatterParams) => {
    const revenue = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (typeof revenue === 'number' && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(revenue, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalRevenue : null;
  },
};

export const CabinClassSourceFlightAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightAverageFare,
  headerName: t('source_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_avg_fare'),
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (averageFare && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(averageFare, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.ndoAverageFare : null;
  },
};

export const CabinClassSourceFlightBoardedAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightBoardedAverageFare,
  headerName: t('source_brd_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_brd_avg_fare'),
  valueFormatter: (params: ValueFormatterParams) => {
    const boardedAverageFare = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (boardedAverageFare && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(boardedAverageFare, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalBoardedAverageFare : null;
  },
};

export const CabinClassSourceFlightFinalAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.SourceFlightFinalAverageFare,
  headerName: t('source_fn_avg_fare_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  hide: true,
  headerTooltip: t('source_fn_avg_fare'),
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;
    const data = params.data as FlightViewFlightLineRow;

    if (averageFare && data.sourceFlight?.fareCurrency) {
      return FormatService.amountWithCurrency(averageFare, data.sourceFlight.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightViewFlightLineRow;
    return data.sourceFlight ? data.sourceFlight.finalAverageFare : null;
  },
};
