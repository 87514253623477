import { CellClassParams, ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { uniq } from 'lodash-es';

import { FlightStatusClosed } from '@/models/enums';
import { ColumnId } from '@/models/enums/grid';
import { FlightCalendarEvent, FlightLegModel, FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { useGlobalLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { LinkedClassRuleModel } from '@/modules/rules/linked-class-rules/models/linked-class-rule.model';
import { useGlobalRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { RivalRuleModel } from '@/modules/rules/rival-rules/models/rival-rule.model';
import {
  DateComparator,
  DateInvertedComparator,
  DateTimeInvertedComparator,
  DayOfWeekComparator,
  StringOrNumberComparator,
  TimeComparator,
} from '@/modules/shared/utils/comparisons.utils';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { ITag } from '@/modules/tags';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { i18n } from '@/plugins/i18n';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

import {
  BaseTagStyle,
  DateColumnFilterSettings,
  NumberColumnFilterSettings,
  SetColumnFilterSettings,
  TextColumnFilterSettings,
  getLoadFactorCellRenderParams,
} from './base';

const { t } = i18n.global;

export const LastUserActivityColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.LastUserActivity,
  headerName: t('last_user_action_short'),
  field: 'userDateModified',
  type: 'numericColumn',
  minWidth: 70,
  width: 70,
  sortable: true,
  headerTooltip: t('last_user_action'),
  cellRenderer: 'GridLastActivityRenderer',
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateTimeInvertedComparator,
  },
  comparator: DateTimeInvertedComparator,
  cellRendererParams: (params: ICellRendererParams) => (params.data as FlightLineModel).userDateModified,
};

export const LastSystemUserActivityColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.LastSystemUserActivity,
  headerName: t('last_eddy_action_short'),
  field: 'systemUserDateModified',
  type: 'numericColumn',
  minWidth: 70,
  width: 70,
  sortable: true,
  headerTooltip: t('last_eddy_action'),
  cellRenderer: 'GridLastActivityRenderer',
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateTimeInvertedComparator,
  },
  comparator: DateTimeInvertedComparator,
  cellRendererParams: (params: ICellRendererParams) => (params.data as FlightLineModel).systemUserDateModified,
};

export const LastUserReviewColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.LastUserReview,
  headerName: t('last_user_review_short'),
  headerTooltip: t('last_user_review'),
  field: 'lastViewedDateTime',
  type: 'numericColumn',
  minWidth: 70,
  width: 70,
  sortable: true,
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateTimeInvertedComparator,
  },
  comparator: DateTimeInvertedComparator,
  valueFormatter: (params: ValueFormatterParams) => FormatService.humanizeTimestamp((params.data as FlightLineModel).lastViewedDateTime),
};

export const LastBookingDateColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.LastBookingDate,
  headerName: t('last_booking_date'),
  headerTooltip: t('last_booking'),
  field: 'lastBookingDate',
  type: 'numericColumn',
  minWidth: 60,
  width: 60,
  sortable: true,
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateInvertedComparator,
  },
  comparator: DateComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const systemStore = useSystemStore();

    if (FlightService.hasRealTimeDate(params.data)) {
      return `${t('n_days', 0)}`;
    } else if (DateTimeService.isValidDate({ date: params.value })) {
      const diff = DateTimeService.calculateLastBookingCaptureDateDifference(systemStore.config!.captureDate, params.value);

      return diff ? `-${t('n_days', diff)}` : '';
    } else {
      return '';
    }
  },
};

export const CarrierCodeColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.CarrierCode,
  headerName: t('general.carrier_code_short'),
  field: 'carrierCode',
  type: 'leftAligned',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('general.carrier_code'),
};

export const OriginColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Origin,
  headerName: t('origin_short'),
  field: 'origin',
  type: 'leftAligned',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('general.origin'),
};

export const DestinationColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Destination,
  headerName: t('destination_short'),
  field: 'destination',
  type: 'leftAligned',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('general.destination'),
};

export const FlightNumbersColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.FlightNumbers,
  headerName: t('general.flight_number_short'),
  field: 'flightNumber',
  type: 'numericColumn',
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  headerTooltip: t('general.flight_number'),
  valueGetter: (params: ValueGetterParams) => FlightService.getFlightNumbersFromFlightLine((params.data as FlightLineModel).flightLine)[0],
  valueFormatter: (params: ValueFormatterParams) =>
    FlightService.getFlightNumbersFromFlightLine((params.data as FlightLineModel).flightLine).join(':'),
};

export const DepartureDateColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.DepartureDate,
  headerName: t('departure_date_short'),
  field: 'departureDate',
  minWidth: 85,
  width: 85,
  sortable: true,
  sort: 'asc',
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateInvertedComparator,
  },
  comparator: DateComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const date = FormatService.format('date', params.data.departureDate);

    return date;
  },
  headerTooltip: t('general.departure_date'),
};

export const DepartureTimeColumn: ColDef = {
  colId: ColumnId.DepartureTime,
  headerName: t('departure_time_short'),
  field: 'departureTime',
  minWidth: 50,
  width: 50,
  type: 'numericColumn',
  sortable: true,
  suppressMenu: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.format('dateTime', params.value),
  headerTooltip: t('departure_time'),
  comparator: TimeComparator,
};

export const LastRealTimeUpdateColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.LastRealTimeUpdate,
  headerName: t('real_time_bookings_update'),
  field: 'lastRealTimeUpdate',
  minWidth: 50,
  width: 50,
  type: 'numericColumn',
  sortable: true,
  suppressMenu: true,
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateTimeInvertedComparator,
  },
  comparator: DateTimeInvertedComparator,
  valueFormatter: (params: ValueFormatterParams) => FormatService.humanizeTimestamp((params.data as FlightLineModel).lastRealTimeUpdate),
  headerTooltip: t('real_time_bookings_update'),
};

export const MaxLegBookingsColumn = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.MaxLegBookings,
  headerName: t('max_leg_bk_short'),
  type: 'numericColumn',
  field: 'aircraftMaxLegBookings',
  minWidth: 35,
  width: 35,
  headerTooltip: t('max_leg_bk'),
  sortable: true,
};

export const NdoColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.Ndo,
  headerName: t('general.ndo'),
  field: 'departureDate',
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const value = params.data.departureDate;
    const systemStore = useSystemStore();

    if (value) {
      return DateTimeService.calculateDateDifference(value, systemStore.config!.captureDate);
    }
  },
  headerTooltip: t('general.ndo_explained'),
};

export const DayOfWeekColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.DayOfWeek,
  headerName: t('day_of_week_short'),
  field: 'departureDate',
  minWidth: 35,
  type: 'leftAligned',
  width: 35,
  sortable: true,
  valueGetter: (params: ValueGetterParams) =>
    DateTimeService.formatDate({
      date: params.data.departureDate,
      format: 'ddd',
    }),
  filterParams: {
    comparator: (valueA: string, valueB: string) => {
      const valA = DateTimeService.defaultWeekdays().indexOf(valueA);
      const valB = DateTimeService.defaultWeekdays().indexOf(valueB);

      return valB - valA;
    },
    newRowsAction: 'keep',
    buttons: ['reset'],
  },
  comparator: DayOfWeekComparator,
  headerTooltip: t('day_of_week'),
};

export const OvernightsColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Overnights,
  headerName: t('overnights_short'),
  headerClass: 'ag-left-aligned-header',
  cellClass: 'ag-left-aligned-cell',
  field: 'overnights',
  sortable: true,
  type: 'numericColumn',
  hide: true,
  minWidth: 30,
  width: 30,
  headerTooltip: t('overnights'),
  aggFunc: 'sum',
};

export const ArrivalDateColumn: ColDef = {
  ...DateColumnFilterSettings,
  colId: ColumnId.ArrivalDate,
  headerName: t('arrival_date_short'),
  field: 'arrivalDate',
  sortable: true,
  type: 'numericColumn',
  hide: true,
  minWidth: 85,
  width: 85,
  filterParams: {
    ...DateColumnFilterSettings?.filterParams,
    comparator: DateInvertedComparator,
  },
  comparator: DateComparator,
  valueFormatter: (params: ValueFormatterParams) => FormatService.format('date', params.value),
  headerTooltip: t('arrival_date'),
};

export const ArrivalTimeColumn: ColDef = {
  colId: ColumnId.ArrivalTime,
  headerName: t('arrival_time_short'),
  field: 'arrivalTime',
  sortable: true,
  type: 'numericColumn',
  hide: true,
  minWidth: 50,
  width: 50,
  suppressMenu: true,
  valueFormatter: (params: ValueFormatterParams) => FormatService.format('dateTime', params.value),
  headerTooltip: t('arrival_time'),
};

export const TagsColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.Tags,
  headerName: t('tags'),
  sortable: true,
  field: 'tags',
  minWidth: 40,
  width: 40,
  type: 'leftAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-tags-key-cell-${data.ondId}`,
  cellRenderer: 'GridStringListRenderer',
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    if (data.tags) {
      return data.tags.map((tag) => tag.name).join();
    }
  },
  cellRendererParams: (params: ValueFormatterParams<FlightLineModel>) => ({
    value: params.data?.tags?.map((tag: ITag) => tag.name) || [],
  }),
  comparator: StringOrNumberComparator,
};

export const FlightRouteGroupsColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.RouteGroups,
  headerName: t('route_groups'),
  sortable: true,
  field: 'routeGroups',
  minWidth: 40,
  width: 40,
  type: 'leftAligned',
  cellRenderer: 'GridRouteGroupsRenderer',
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    if (data.routeGroups) {
      return data.routeGroups.map((routeGroup) => routeGroup.name).join();
    }
  },
  cellRendererParams: (params: ValueFormatterParams) => ({
    value: params.data.routeGroups,
  }),
  comparator: StringOrNumberComparator,
};

export const ClosedStatusColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.ClosedStatus,
  headerName: t('closed_short'),
  field: 'closed',
  minWidth: 60,
  width: 60,
  sortable: true,
  hide: true,
  headerTooltip: t('closed'),
  cellStyle: (params: CellClassParams) => {
    const style = BaseTagStyle;

    switch (params.value) {
      case FlightStatusClosed.closed:
        return { ...style, color: '#f56c6c' };
      case FlightStatusClosed.open:
        return { ...style, color: '#67c23a' };
      default:
        return {};
    }
  },
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return data.closed ? FlightStatusClosed.closed : FlightStatusClosed.open;
  },
};

export const StatusCodeManualColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.StatusCodeManual,
  headerName: t('status_code_manual_short'),
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('status_code_manual'),
  cellStyle: (params: CellClassParams) => {
    const style = BaseTagStyle;

    switch (params.value) {
      case t('on'):
        return { ...style, color: '#409EFF' };
      case t('off'):
        return { ...style, color: '#909399' };
      default:
        return {};
    }
  },
  valueGetter: (params: ValueGetterParams) => (params.data.statusCodeManual ? t('on') : t('off')),
};

export const BlockSpaceColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.BlockSpace,
  headerName: t('codeshare_block_space_short'),
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('codeshare_block_space'),
  cellStyle: (params: CellClassParams) => {
    const style = BaseTagStyle;

    switch (params.value) {
      case t('on'):
        return { ...style, color: '#409EFF' };
      case t('off'):
        return { ...style, color: '#909399' };
      default:
        return {};
    }
  },
  valueGetter: (params: ValueGetterParams<FlightLineModel>) => (params.data?.codeShareBlockspace ? t('on') : t('off')),
};

export const CappedFreeFlowColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.CappedFreeFlow,
  headerName: t('codeshare_capped_free_flow_short'),
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('codeshare_capped_free_flow'),
  cellStyle: (params: CellClassParams) => {
    const style = BaseTagStyle;

    switch (params.value) {
      case t('on'):
        return { ...style, color: '#409EFF' };
      case t('off'):
        return { ...style, color: '#909399' };
      default:
        return {};
    }
  },
  valueGetter: (params: ValueGetterParams<FlightLineModel>) => (params.data?.codeShareCappedFreeFlow ? t('on') : t('off')),
};

export const UniqueFlightIdColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.UniqueFlightId,
  headerName: t('flight_key'),
  field: 'uniqueFlightId',
  minWidth: 200,
  width: 200,
  headerCheckboxSelection: true,
  hide: false,
  sortable: true,
  lockPosition: true,
  lockPinned: true,
  checkboxSelection: true,
  lockVisible: true,
  pinned: 'left',
  type: 'leftAligned',
  headerClass: 'data-test-flight-key-header',
  cellClass: ({ data }: CellClassParams) => `data-test-flight-key-cell data-test-flight-ond-id-${data.ondId}`,
  cellRenderer: 'GridFlightLineClassOverviewRenderer',
  valueGetter: (params: ValueGetterParams) => (params?.data as FlightLineModel)?.uniqueFlightId,
};

export const FlightFareCurrencyColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.FlightFareCurrency,
  headerName: t('general.currency_short'),
  field: 'fareCurrency',
  type: 'leftAligned',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('fare_currency_type'),
};

// this is only for the fare amount not for the whole revenue even though the name is "summed revenue"
export function generateSummedRevenueColumn(): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: ColumnId.SummedRevenue,
    headerName: t('revenue_short'),
    headerTooltip: t('revenue'),
    field: 'totalRevenue.fare.amount',
    type: 'numericColumn',
    width: 40,
    minWidth: 40,
    sortable: true,
    comparator: StringOrNumberComparator,
    valueFormatter: (params: ValueFormatterParams) => {
      const revenue = params.value;

      if (revenue || revenue === 0) {
        return FormatService.amountWithoutCurrency(revenue, params.data?.fareCurrency);
      }

      return '';
    },
  };
}

export const FlightAverageFareColumn: ColDef = {
  ...NumberColumnFilterSettings,
  filterParams: {
    ...NumberColumnFilterSettings?.filterParams,
    filterOptions: ['greaterThan', 'inRange', 'lessThan'],
    defaultOption: 'greaterThan',
  },
  colId: ColumnId.FlightAverageFare,
  headerName: t('average_fare_short'),
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerTooltip: t('average_fare'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const { averageFare } = params.data;

    if (averageFare || averageFare === 0) {
      return Math.round(averageFare);
    }

    return '';
  },
  valueFormatter: (params: ValueFormatterParams) => {
    const { averageFare, fareCurrency } = params.data;

    if (averageFare && fareCurrency) {
      return FormatService.amountWithoutCurrency(averageFare, fareCurrency);
    }

    return '';
  },
};

export const LidColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.FlightLid,
  headerName: t('general.lid_short'),
  width: 35,
  minWidth: 35,
  type: 'numericColumn',
  sortable: true,
  headerTooltip: t('general.lid'),
  headerClass: 'ag-right-aligned-header data-test-flight-lid-header',
  cellClass: 'ag-right-aligned-cell data-test-pin-count-cell',
  valueGetter: (params: ValueGetterParams) => {
    const { data } = params;
    return data.aircraftLid;
  },
};

export const CapacityColumn: ColDef = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.FlightCapacity,
  headerName: t('general.capacity_short'),
  width: 35,
  minWidth: 35,
  type: 'numericColumn',
  sortable: true,
  headerTooltip: t('general.capacity'),
  headerClass: 'ag-right-aligned-header data-test-capacity-header',
  cellClass: 'ag-right-aligned-cell data-test-pin-count-cell',
  valueGetter: (params: ValueGetterParams) => {
    const { data } = params;
    return data.aircraftSaleableCapacity;
  },
};

export const PinsColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.Pins,
  headerName: t('pin_count_column_header'),
  width: 15,
  minWidth: 15,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerTooltip: t('pins_count'),
  headerClass: 'ag-left-aligned-header data-test-pin-count-header',
  cellClass: 'data-test-pin-count-cell',
  valueGetter: (params: ValueGetterParams) => {
    const { data } = params;
    return data.pins;
  },
};

export const LinkedClassRuleNameColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RuleName,
  headerName: t('linked_class_rule_name_short'),
  minWidth: 35,
  width: 35,
  type: 'leftAligned',
  sortable: true,
  hide: false,
  headerTooltip: t('linked_class_rule_name'),
  cellClass: ({ data }: CellClassParams) => `data-test-linked-class-rule-key-cell-${data.ondId}`,
  valueGetter: (params: ValueGetterParams) => {
    const linkedClassRuleId = (params.data as FlightLineModel).linkedClassRuleId;
    const linkedClassRule = useGlobalLinkedClassRule().linkedClassRules.value.find(
      (linkedClassRule: LinkedClassRuleModel) => linkedClassRule.id === linkedClassRuleId,
    );
    return linkedClassRule?.name;
  },
  comparator: StringOrNumberComparator,
};

export const RivalRuleNameColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.RivalRuleName,
  headerName: t('rival_rule_name_short'),
  minWidth: 35,
  width: 35,
  type: 'leftAligned',
  sortable: true,
  hide: false,
  headerTooltip: t('rival_rule_name'),
  valueGetter: (params: ValueGetterParams) => {
    const rivalRuleId = (params.data as FlightLineModel).rivalRuleId;
    const rivalRule = useGlobalRivalRule().rivalRules.value.find((rivalRule: RivalRuleModel) => rivalRule.id === rivalRuleId);
    return rivalRule?.name;
  },
  comparator: StringOrNumberComparator,
  requiredPermission(params) {
    return !!params.customerSettings.hasRivalRulesEnabled;
  },
};

export const FlightBookingsColumn = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.FlightBookings,
  headerName: t('bookings_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('bookings'),
  valueGetter: (params: ValueGetterParams) => params.data.aircraftBookings,
};

export const BookingDetailsColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.BookingDetails,
  headerName: t('booking_details.control.booking_details'),
  headerClass: 'ag-left-aligned-header',
  headerTooltip: t('booking_details.control.booking_details'),
  valueGetter: (params: ValueGetterParams<FlightLineModel>) =>
    (params.data?.aircraftBookings ?? 0) > 0 ? t('booking_details.labels.with_bookings') : t('booking_details.labels.without_bookings'),
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridBookingDetailsRenderer',
  requiredPermission: ({ customerSettings }) => !!customerSettings.hasBookingDetailsEnabled,
};

//Bookings without realtime bookings / value getter. Fix to make export to csv / excel work
export const FlightBookingsNoRealTimeColumn = {
  ...NumberColumnFilterSettings,
  colId: ColumnId.FlightBookings,
  headerName: t('bookings_short'),
  type: 'numericColumn',
  field: 'aircraftBookings',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('bookings'),
};

export const FlightEquipmentTypeColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.FlightEquipmentType,
  headerName: t('control.columns.equipment_type_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('control.columns.equipment_type'),
  valueGetter: (params: ValueGetterParams) => {
    const { data } = params;
    if (data.legs && data.legs.length > 0) {
      const eqType: string[] = [];
      data.legs.forEach((leg: FlightLegModel) => {
        eqType.push(leg.equipmentType);
      });
      return uniq(eqType);
    }
  },
};

export const FlightEquipmentVersionColumn = {
  ...SetColumnFilterSettings,
  colId: ColumnId.FlightEquipmentVersion,
  type: 'numericColumn',
  headerName: t('control.columns.equipment_version_short'),
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('control.columns.equipment_version'),
  valueGetter: (params: ValueGetterParams) => uniq((params?.data as FlightLineModel)?.legs?.map((item) => item.equipmentVersion)),
};

export const FlightLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.AircraftLidLoadFactor,
  headerName: t('authorized_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  comparator: StringOrNumberComparator,
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return Number(FormatService.roundNumber(data.aircraftLidLoadFactor, 1));
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const data = params.data as FlightLineModel;
    return getLoadFactorCellRenderParams(data.aircraftLidLoadFactor, lafLoadFactorColoring, 'flight');
  },
  headerTooltip: t('lid_load_factor'),
});

export const FlightCapacityLoadFactorColumn = (lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: ColumnId.CabinCapacityLoadFactor,
  headerName: t('saleable_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  comparator: StringOrNumberComparator,
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const data = params.data as FlightLineModel;
    return Number(FormatService.roundNumber(data.aircraftCapacityLoadFactor, 1));
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const data = params.data as FlightLineModel;
    return getLoadFactorCellRenderParams(data.aircraftCapacityLoadFactor, lafLoadFactorColoring, 'flight');
  },
  headerTooltip: t('capacity_load_factor'),
});

export const FlightCalendarEventColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.CalendarEvent,
  cellClass: 'data-test-flight-calendar-event-column',
  headerName: t('event_management.labels.assigned_events'),
  headerClass: 'ag-left-aligned-header',
  valueGetter: (params: ValueGetterParams<FlightLineModel>) =>
    params.data?.hasCalendarEvents ? t('event_management.labels.with_events') : t('event_management.labels.without_events'),
  minWidth: 75,
  width: 75,
  cellRenderer: 'GridCalendarEventRenderer',
};

export const FlightCalendarEventNamesColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.CalendarEventNames,
  cellClass: ({ data }: CellClassParams) => `data-test-flight-calendar-event-names-key-cell-${data.ondId}`,
  headerName: t('event_management.labels.event_names'),
  headerClass: 'ag-left-aligned-header',
  valueGetter: (params: ValueGetterParams<FlightLineModel>) =>
    params.data?.calendarEvents?.map((event: FlightCalendarEvent) => event.displayName),
  minWidth: 75,
  width: 75,
  cellRenderer: 'GridStringListRenderer',
  cellRendererParams: (params: ValueFormatterParams<FlightLineModel>) => ({
    value: params.data?.calendarEvents?.map((calendarEvent: FlightCalendarEvent) => calendarEvent.displayName) || [],
  }),
  sortable: true,
  comparator: StringOrNumberComparator,
};

export const FlightCalendarEventClustersColumn: ColDef = {
  ...TextColumnFilterSettings,
  colId: ColumnId.CalendarEventClusters,
  cellClass: ({ data }: CellClassParams) => `data-test-flight-calendar-event-clusters-key-cell-${data.ondId}`,
  headerName: t('event_management.labels.event_clusters'),
  headerClass: 'ag-left-aligned-header',
  valueGetter: (params: ValueGetterParams<FlightLineModel>) =>
    params.data?.calendarEvents?.map((calendarEvent: FlightCalendarEvent) =>
      calendarEvent.eventCluster
        ? t('event_management.labels.calendar_event_cluster.' + calendarEvent.eventCluster.toLowerCase().split('-').join('_'))
        : '',
    ),
  minWidth: 75,
  width: 75,
  cellRenderer: 'GridStringListRenderer',
  cellRendererParams: (params: ValueFormatterParams<FlightLineModel>) => ({
    value:
      params.data?.calendarEvents
        ?.map((calendarEvent: FlightCalendarEvent) =>
          calendarEvent.eventCluster
            ? t('event_management.labels.calendar_event_cluster.' + calendarEvent.eventCluster.toLowerCase().split('-').join('_'))
            : '',
        )
        .filter((value, index, array) => array.indexOf(value) === index) || [],
  }),
  sortable: true,
  comparator: StringOrNumberComparator,
};

export const SalesConfigColumn: ColDef = {
  ...SetColumnFilterSettings,
  colId: ColumnId.SalesConfig,
  headerName: t('control.columns.sales_configuration_short'),
  minWidth: 35,
  width: 35,
  sortable: true,
  hide: true,
  headerTooltip: t('control.columns.sales_configuration'),
  valueGetter: ({ data }: ValueGetterParams) => {
    if (data.legs?.length) {
      return uniq(data.legs.map((leg: FlightLegModel) => leg.salesConfig));
    }
  },
};
