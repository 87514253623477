import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

import { logger } from '@/modules/monitoring';
import { routesService } from '@/modules/route-management';
import { ClusterAssignment } from '@/modules/route-management/api/route-cluster.contracts';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { MessageService } from '@/modules/shared';

export type SlimRouteModel = Omit<RouteModel, 'cabinsWithClusters'>;

export const useRouteManagementStore = defineStore('routeManagement', () => {
  const isLoading: Ref<boolean> = ref(false);
  const routes: Ref<SlimRouteModel[]> = ref([]);
  const routeClustersMap: Ref<Map<number, Map<string, ClusterAssignment[]>>> = ref(new Map());

  async function get(): Promise<void> {
    try {
      isLoading.value = true;

      const fetchedRoutes: RouteModel[] = await routesService.getAllRoutes('withUsers');

      // Disable the rule as we're just using the fetchedRoutes to get the SlimRouteModel (the 'rest' parameter)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      routes.value = fetchedRoutes.map(({ cabinsWithClusters, ...slimRoute }) => {
        const clustersMap: Map<string, ClusterAssignment[]> = cabinsWithClusters.reduce((acc, cabinWithClusters) => {
          acc.set(cabinWithClusters.cabinCode, cabinWithClusters.clusters);
          return acc;
        }, new Map());

        routeClustersMap.value.set(slimRoute.id, clustersMap);

        return slimRoute;
      });
    } catch (error) {
      logger.error(error as Error);
      MessageService.failedRequest('Failed to fetch routes');
    } finally {
      isLoading.value = false;
    }
  }

  function $reset(): void {
    isLoading.value = false;
    routes.value = [];
    routeClustersMap.value = new Map();
  }

  return {
    get,
    routes,
    routeClustersMap,
    isLoading,
    $reset,
  };
});
