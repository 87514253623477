import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import {
  AirlineProperties,
  ApplicationSettingsModel,
  CabinName,
  ClassStructure,
  DiscreteType,
  FlowFxProperties,
  InventoryConfigurationProperties,
  PickupLimits,
} from '@/modules/api/application/application-contracts';
import { applicationService } from '@/modules/api/application/application-service';
import { store } from '@/store';

export function getClassesByDiscreteType(payload: { discreteType: DiscreteType; selectedCabinCode: string }): ClassStructure[] {
  const configuredCabin = AppSettingsModule.inventoryConfigurationProperties.cabins.find(
    (cabin) => cabin.code === payload.selectedCabinCode,
  );

  if (!configuredCabin) {
    return [];
  }

  return configuredCabin.classes.filter(
    (configuredClass) => configuredClass.discrete && configuredClass.discreteType === DiscreteType.promotion,
  );
}

export function getClassesWithoutTopClass(payload: { classes: ClassStructure[]; cabinName: CabinName }) {
  return payload.classes.filter(
    (clazz) =>
      (payload.cabinName === CabinName.Economy && clazz.code !== AppSettingsModule.topClassEconomyCabin.code) ||
      (payload.cabinName === CabinName.Business && clazz.code !== AppSettingsModule.topClassBusinessCabin.code) ||
      (payload.cabinName === CabinName.PremiumEconomy && clazz.code !== AppSettingsModule.topClassPremiumEconomyCabin.code),
  );
}

export function getCabinCodeForClass(classCode: string): string {
  return AppSettingsModule.inventoryConfigurationProperties.cabins.find((cabin) =>
    cabin.classes.some((cabinClass) => cabinClass.code === classCode),
  ).code;
}

export interface IAppSettingsState {
  inventoryConfigurationProperties: InventoryConfigurationProperties;
  baseCurrency: string;
  airlineProperties: AirlineProperties;
}

@Module({ dynamic: true, store, name: 'appSettings', namespaced: true })
class AppSettings extends VuexModule implements IAppSettingsState {
  public inventoryConfigurationProperties: InventoryConfigurationProperties = null;
  public baseCurrency = '';
  public airlineProperties: AirlineProperties = {};
  public flowFxProperties: FlowFxProperties = { performanceBandPickupConditionDayList: [] };
  public pickupLimits: PickupLimits = {};

  // Actions
  @Action
  public async getApplicationSettings() {
    try {
      const response = await applicationService.getSettings();
      this.setApplicationSettings(response);
    } catch (error) {
      return error;
    }
  }

  get cabinClassesWithOutTopClasses() {
    return this.inventoryConfigurationProperties.cabins.map((cabin) => ({
      ...cabin,
      classes: getClassesWithoutTopClass({
        cabinName: cabin.name,
        classes: cabin.classes,
      }),
    }));
  }

  get classesWithOutTopClasses() {
    return this.inventoryConfigurationProperties.cabins.flatMap((cabin) =>
      getClassesWithoutTopClass({
        cabinName: cabin.name,
        classes: cabin.classes,
      }),
    );
  }

  get classes() {
    return this.inventoryConfigurationProperties.cabins.flatMap((cabin) => cabin.classes);
  }

  get economyCabin() {
    return this.inventoryConfigurationProperties.cabins.find((cabin) => cabin.name === CabinName.Economy);
  }

  get businessCabin() {
    return this.inventoryConfigurationProperties.cabins.find((cabin) => cabin.name === CabinName.Business);
  }

  get premiumEconomyCabin() {
    return this.inventoryConfigurationProperties.cabins.find((cabin) => cabin.name === CabinName.PremiumEconomy);
  }

  get topClassEconomyCabin() {
    return this.economyCabin.classes[0];
  }

  get topClassBusinessCabin() {
    return this.businessCabin.classes[0];
  }

  get topClassPremiumEconomyCabin() {
    return this.premiumEconomyCabin.classes[0];
  }

  get timezoneId() {
    return this.airlineProperties?.timezoneId;
  }

  get flowFxPerformanceBandPickUpDays() {
    return this.flowFxProperties.performanceBandPickupConditionDayList;
  }

  // Mutations
  @Mutation
  public setApplicationSettings(payload: ApplicationSettingsModel) {
    this.inventoryConfigurationProperties = payload.inventoryConfigurationProperties;
    this.baseCurrency = payload.baseCurrency.code;
    this.airlineProperties = payload.airlineProperties;
    this.flowFxProperties = payload.flowFxProperties;
    this.pickupLimits = payload.pickupLimits;
  }
}

export const AppSettingsModule = getModule(AppSettings);
