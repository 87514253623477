export const en = {
  docs: {
    title: {
      doc_hub: 'Documentation Hub',
      release_notes: 'Release Notes',
    },
    labels: {
      published: 'Published:',
      modified: 'Last modified:',
      using_version: 'Using Version:',
      n_a: 'N/A',
    },
    info: {
      no_release_notes: 'No release notes for version {version} yet 👀',
    },
    headings: {
      on_this_page: 'On this page',
    },
    feedback_form: {
      feedback: {
        question: 'Are These Release Notes Helpful?',
        placeholder: 'Yes, but it’d be wonderful if...',
      },
    },
  },
};
