import { ColDef, ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import { FlightLineColumnsGeneratorValuesInterface } from '@/models/columns/collections/flightlines/index';
import {
  getSharedControlFlightLineLegCabinColumns,
  getSharedControlSourceFlightColumns,
} from '@/models/columns/collections/flightlines/shared';
import * as Columns from '@/models/columns/definitions';
import { UniqueFlightIdColumn, generateBookingsPickupCols } from '@/models/columns/definitions';
import { ColumnGroupId, ColumnId, generateCabinPickupColumnId, generateFlightPickupColumnId } from '@/models/enums/grid';
import { CabinName, CabinStructure, PssName } from '@/modules/api/application/application-contracts';
import { generateAncillaryCabinColumns, generateAncillaryFlightColumns } from '@/modules/revenues/columns/shared';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { i18n } from '@/plugins/i18n';
import { ColumnService } from '@/services/column.service';
import { InventoryStructureService } from '@/services/inventory-structure.service';

const { t } = i18n.global;

const generateFlightLineFlightColumnGroup1d = (
  bookingsPickupDays: number[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): ColGroupDef => ({
  headerName: t('general.flight'),
  groupId: ColumnGroupId.FlightGroup,
  children: [
    Columns.LastUserActivityColumn,
    Columns.LastSystemUserActivityColumn,
    Columns.LastUserReviewColumn,
    Columns.CarrierCodeColumn,
    Columns.OriginColumn,
    Columns.DestinationColumn,
    Columns.FlightNumbersColumn,
    Columns.DepartureDateColumn,
    Columns.DepartureTimeColumn,
    Columns.NdoColumn,
    Columns.DayOfWeekColumn,
    Columns.OvernightsColumn,
    Columns.ArrivalDateColumn,
    Columns.ArrivalTimeColumn,
    Columns.TagsColumn,
    Columns.FlightCalendarEventColumn,
    Columns.FlightCalendarEventNamesColumn,
    Columns.FlightCalendarEventClustersColumn,
    Columns.FlightBookingsNoRealTimeColumn,
    Columns.LastBookingDateColumn,
    Columns.BookingDetailsColumn,
    Columns.LastRealTimeUpdateColumn,
    Columns.FlightRouteGroupsColumn,
    Columns.FlightEquipmentTypeColumn,
    Columns.FlightEquipmentVersionColumn,
    Columns.FlightFareCurrencyColumn,
    Columns.generateSummedRevenueColumn(),
    Columns.FlightLoadFactorColumn(lafLoadFactorColoring),
    Columns.FlightCapacityLoadFactorColumn(lafLoadFactorColoring),
    Columns.FlightAverageFareColumn,
    Columns.CapacityColumn,
    Columns.LidColumn,
    Columns.MaxLegBookingsColumn,
    Columns.PinsColumn,
    Columns.LinkedClassRuleNameColumn,
    Columns.RivalRuleNameColumn,
    ...generateBookingsPickupCols('flight', bookingsPickupDays),
    ...generateAncillaryFlightColumns(),
  ],
});

const FlightLineLegColumnGroup = (lafLoadFactorColoring: LafLoadFactorColoring): ColGroupDef => ({
  headerName: t('leg'),
  groupId: ColumnGroupId.AircraftGroup,
  children: [
    Columns.LegSaleableCapacityColumn,
    Columns.LegAuthorizedCapacityColumn,
    Columns.LegAuthorizedCapacityLoadFactorColumn(lafLoadFactorColoring),
    Columns.LegSaleableCapacityLoadFactorColumn(lafLoadFactorColoring),
  ],
});

const generateFlightLineCabinColumnGroup1d = (generatorValues: FlightLineColumnsGeneratorValuesInterface): ColGroupDef[] =>
  generatorValues.cabins.map((cabin: CabinStructure) => {
    const children = [
      Columns.generateCabinLoadFactorLafColumn(cabin.code),
      Columns.generateCabinSpillageSpoilageColumn(cabin.code),
      Columns.generateCabinOptimizationTacticsColumn(cabin.code),
      Columns.generateCabinLoadFactorColumn(cabin.code, generatorValues.lafLoadFactorColoring),
      Columns.generateCabinCapacityLoadFactorColumn(cabin.code, generatorValues.lafLoadFactorColoring),
      Columns.generateCabinLowestAvailableFareColumn(cabin.code, cabin.classes, generatorValues.lafLoadFactorColoring),
      Columns.generateCabinSeatAvailabilityColumn(cabin.code),
      Columns.generateCabinAutopilotColumn(cabin.code),
      Columns.generateCabinPacingPerformanceBandColumn(cabin.code),
      Columns.generateCabinPacingPerformanceScoreColumn(cabin.code),
      Columns.generateCabinClusterColumn(cabin.code),
      Columns.generateCabinCurrencyColumn(cabin.code),
      Columns.generateCabinLowestAvailableFarePriceColumn(cabin.code, generatorValues.customerSettings.ownFareSource),
      Columns.generateCabinCompetitiveFareColumn(cabin.code, generatorValues.customerSettings.ownFareSource),
      Columns.generateCabinCompetitiveFareDifferenceColumn(cabin.code, generatorValues.customerSettings.ownFareSource),
      Columns.generateCabinCompetitiveFarePercentageDifferenceColumn(cabin.code),
      ...Columns.generateCabinCompetitorFareColumns(
        cabin.code,
        generatorValues.competitorCarrierCodes,
        generatorValues.customerSettings.ownFareSource,
      ),
      Columns.generateCabinMinRivalFareColumn(cabin.code, generatorValues.customerSettings.ownFareSource),
      Columns.generateCabinRivalFareDifferenceColumn(cabin.code, generatorValues.customerSettings.ownFareSource),
      Columns.generateCabinRivalFarePercentageDifferenceColumn(cabin.code),
      Columns.generateCabinSummedRevenueColumn(cabin.code),
      Columns.generateCabinAverageFareColumn(cabin.code),
      Columns.generateMaxLegBookingsColumn(cabin.code),
      Columns.generateCabinBookingsColumn(cabin.code),
      Columns.generateCabinCapacityColumn(cabin.code),
      Columns.generateCabinLidColumn(cabin.code),
      Columns.generateCabinLafFareValueColumn(cabin.code),
      ...Columns.generateBookingsPickupCols('cabin', generatorValues.bookingsPickupDays, cabin.code),
      ...Columns.generatePerformanceBandPickupCols('cabin', generatorValues.performanceBandPickupDays, cabin.code),
      ...Columns.generateDataScienceCabinColumns(cabin.code, cabin.classes),
      Columns.generateCabinPinnedClassesColumn(cabin.code, cabin.classes),
      Columns.generateCabinPinnedClassesCountColumn(cabin.code),
      ...generateAncillaryCabinColumns(cabin.code),
      Columns.generateCabinRecommendedLowestAvailableFareColumn(cabin.code, cabin.classes, generatorValues.lafLoadFactorColoring),
    ];

    if (generatorValues.customerSettings.hasShadowTacticsEnabled) {
      children.push(
        Columns.generateCabinShadowOptimizationTacticsColumn(cabin.code),
        Columns.generateCabinShadowRecommendedLowestAvailableFareColumn(cabin.code, cabin.classes, generatorValues.lafLoadFactorColoring),
      );
    }

    if (generatorValues.customerSettings?.hasNegoAllocationEnabled && cabin.name === CabinName.Economy) {
      children.push(Columns.generateCabinNegotiatedAllocationsColumn(cabin.code));
    }

    return {
      headerName: `${t('general.flight')} | ${t('cabin')} ${cabin.code}`,
      groupId: `flight-cabin-level-metrics${cabin.code}`,
      headerGroupComponentParams: {
        cabinCode: cabin.code,
      },
      children,
    };
  });

export const generateFlightLineClassColumnGroup = (cabins: CabinStructure[]): ColGroupDef[] => {
  const classColumns: ColGroupDef[] = cabins.flatMap((cabin) => {
    const classes = CabinService.getCabinClasses(PssName.Radixx, cabins, cabin.code);
    return [
      Columns.generateCabinClassBookingsColumns(cabin.code, classes),
      Columns.generateCabinClassAuthorizationUnitsColumns(cabin.code, classes),
      Columns.generateCabinClassProtectionColumns(cabin.code, classes),
      Columns.generateCabinClassSeatAvailabilityColumns(cabin.code, classes),
      Columns.generateCabinClassGroupBookingColumns(cabin.code, classes), // 1a
    ];
  });

  // Hide all class-level columns by default
  classColumns.forEach((colOrGroup) => {
    colOrGroup.children.forEach((col: ColDef) => {
      col.hide = true;
    });
  });
  return classColumns;
};

const generateFlightLineLegCabinColumnGroup1d = (generatorValues: FlightLineColumnsGeneratorValuesInterface): ColGroupDef[] =>
  getSharedControlFlightLineLegCabinColumns(generatorValues);

export const generateFlightLineColumns1d = (generatorValues: FlightLineColumnsGeneratorValuesInterface): Array<ColDef | ColGroupDef> => [
  UniqueFlightIdColumn,
  generateFlightLineFlightColumnGroup1d(generatorValues.bookingsPickupDays, generatorValues.lafLoadFactorColoring),
  FlightLineLegColumnGroup(generatorValues.lafLoadFactorColoring),
  {
    headerName: `${t('general.flight')} | ${t('source_flight')}`,
    groupId: ColumnGroupId.SourceFlightGroup,
    children: getSharedControlSourceFlightColumns(),
  },
  ...generateFlightLineCabinColumnGroup1d(generatorValues),
  ...generateFlightLineClassColumnGroup(generatorValues.cabins),
  ...generateFlightLineLegCabinColumnGroup1d(generatorValues),
];

export const generateDefaultColumnState1d = (generatorValues: FlightLineColumnsGeneratorValuesInterface): ColumnState[] => {
  const sortedCabins = InventoryStructureService.sortCabinsWithEconomyFirst(generatorValues.cabins);

  return [
    { colId: ColumnId.UniqueFlightId, pinned: true, hide: false },
    { colId: ColumnId.Origin, hide: false },
    { colId: ColumnId.Destination, hide: false },
    { colId: ColumnId.FlightNumbers, hide: false },
    { colId: ColumnId.DepartureTime, hide: false },
    { colId: ColumnId.DepartureDate, hide: false },
    { colId: ColumnId.DayOfWeek, hide: false },
    { colId: ColumnId.CarrierCode, hide: true },
    { colId: ColumnId.Ndo, hide: false },
    { colId: ColumnId.Pins, hide: false },
    { colId: ColumnId.RouteGroups, hide: false },
    { colId: ColumnId.Tags, hide: false },
    { colId: ColumnId.CalendarEvent, hide: true },
    { colId: ColumnId.CalendarEventNames, hide: true },
    { colId: ColumnId.CalendarEventClusters, hide: true },
    { colId: ColumnId.Overnights, hide: true },
    { colId: ColumnId.ArrivalDate, hide: true },
    { colId: ColumnId.ArrivalTime, hide: true },
    { colId: ColumnId.LastUserActivity, hide: false },
    { colId: ColumnId.LastUserReview, hide: false },
    { colId: ColumnId.FlightFareCurrency, hide: false },
    { colId: ColumnId.SummedRevenue, hide: true },
    { colId: ColumnId.FlightAverageFare, hide: false },
    { colId: ColumnId.MaxLegBookings, hide: false },
    { colId: ColumnId.FlightEquipmentType, hide: false },
    { colId: ColumnId.MaxLegBookings, hide: false },
    { colId: ColumnId.FlightEquipmentVersion, hide: true },
    { colId: ColumnId.FlightCapacity, hide: false },
    { colId: ColumnId.FlightLid, hide: false },
    { colId: ColumnId.AircraftLidLoadFactor, hide: true },
    { colId: ColumnId.LegAircraftCapacityLoadfactor, hide: false },
    { colId: ColumnId.LegAircraftSaleableCapacity, hide: false },
    { colId: ColumnId.FlightBookings, hide: false },
    { colId: ColumnId.LastBookingDate, hide: false },
    { colId: ColumnId.LastRealTimeUpdate, hide: false },
    { colId: ColumnId.RuleName, hide: true },
    { colId: ColumnId.RivalRuleName, hide: true },

    ...generatorValues.bookingsPickupDays.flatMap((pickupDay) => ({
      colId: generateFlightPickupColumnId('bookings', pickupDay),
      hide: false,
    })),

    ...sortedCabins.flatMap((cabin: CabinStructure) => {
      const cabinColumns = [
        {
          colId: Columns.generateCabinLoadFactorLafColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinLoadFactorColumn(cabin.code, generatorValues.lafLoadFactorColoring).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinCapacityLoadFactorColumn(cabin.code, generatorValues.lafLoadFactorColoring).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinSpillageSpoilageColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinLowestAvailableFareColumn(cabin.code, cabin.classes, generatorValues.lafLoadFactorColoring).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinLafFareValueColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinPinnedClassesCountColumn(cabin.code).colId,
          hide: true,
        },
        {
          colId: Columns.generateCabinPinnedClassesColumn(cabin.code, cabin.classes).colId,
          hide: true,
        },
        ...generatorValues.bookingsPickupDays.flatMap((pickupDay) => ({
          colId: generateCabinPickupColumnId(cabin.code, 'bookings', pickupDay),
          hide: false,
        })),
        { colId: Columns.generateCabinCurrencyColumn(cabin.code).colId, hide: false },
        {
          colId: Columns.generateCabinSummedRevenueColumn(cabin.code).colId,
          hide: true,
        },
        {
          colId: Columns.generateCabinAverageFareColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinLowestAvailableFarePriceColumn(cabin.code, generatorValues.customerSettings.ownFareSource).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinCompetitiveFareColumn(cabin.code, generatorValues.customerSettings.ownFareSource).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinCompetitiveFareDifferenceColumn(cabin.code, generatorValues.customerSettings.ownFareSource).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinCompetitiveFarePercentageDifferenceColumn(cabin.code).colId,
          hide: false,
        },
        ...Columns.generateCabinCompetitorFareColumns(
          cabin.code,
          generatorValues.competitorCarrierCodes,
          generatorValues.customerSettings.ownFareSource,
        ).map((it) => ({
          colId: it.colId,
          hide: false,
        })),
        {
          colId: Columns.generateCabinMinRivalFareColumn(cabin.code, generatorValues.customerSettings.ownFareSource).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinRivalFareDifferenceColumn(cabin.code, generatorValues.customerSettings.ownFareSource).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinRivalFarePercentageDifferenceColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinOptimizationTacticsColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinRecommendedLowestAvailableFareColumn(cabin.code, cabin.classes, generatorValues.lafLoadFactorColoring)
            .colId,
          hide: false,
        },
      ];

      if (generatorValues.customerSettings.hasShadowTacticsEnabled) {
        cabinColumns.push({
          colId: Columns.generateCabinShadowOptimizationTacticsColumn(cabin.code).colId,
          hide: true,
        });

        cabinColumns.push({
          colId: Columns.generateCabinShadowRecommendedLowestAvailableFareColumn(
            cabin.code,
            cabin.classes,
            generatorValues.lafLoadFactorColoring,
          ).colId,
          hide: true,
        });
      }

      if (generatorValues.customerSettings.hasNegoAllocationEnabled) {
        cabinColumns.push({
          colId: Columns.generateCabinNegotiatedAllocationsColumn(cabin.code).colId,
          hide: false,
        });
      }

      cabinColumns.push(
        {
          colId: Columns.generateCabinAutopilotColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinPacingPerformanceBandColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinPacingPerformanceScoreColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinClusterColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinSeatAvailabilityColumn(cabin.code).colId,
          hide: true,
        },
        {
          colId: Columns.generateMaxLegBookingsColumn(cabin.code).colId,
          hide: true,
        },
        {
          colId: Columns.generateCabinCapacityColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinLidColumn(cabin.code).colId,
          hide: false,
        },
        {
          colId: Columns.generateCabinBookingsColumn(cabin.code).colId,
          hide: false,
        },
        ...Columns.generateDataScienceCabinColumns(cabin.code, cabin.classes).map((it) => ({
          colId: it.colId,
          hide: false,
        })),
      );
      return cabinColumns;
    }),

    { colId: ColumnId.SourceFlightBookings, hide: true },
    { colId: ColumnId.SourceFlightGroupBookings, hide: true },
    { colId: ColumnId.SourceFlightBoardings, hide: true },
    { colId: ColumnId.SourceFlightFinalBookings, hide: true },
    { colId: ColumnId.SourceFlightFinalGroupBookings, hide: true },

    ...sortedCabins
      .flatMap((cabin) => {
        const classes = CabinService.getCabinClasses(PssName.Radixx, generatorValues.cabins, cabin.code);
        return [
          ColumnService.getColumnStateForColGroup(Columns.generateCabinClassBookingsColumns(cabin.code, classes)),
          ColumnService.getColumnStateForColGroup(Columns.generateCabinClassAuthorizationUnitsColumns(cabin.code, classes)),
          ColumnService.getColumnStateForColGroup(Columns.generateCabinClassProtectionColumns(cabin.code, classes)),
          ColumnService.getColumnStateForColGroup(Columns.generateCabinClassSeatAvailabilityColumns(cabin.code, classes)),
          ColumnService.getColumnStateForColGroup(Columns.generateCabinClassGroupBookingColumns(cabin.code, classes)),
        ];
      })
      .flat(),
  ];
};
