import { cloneDeep, isEmpty } from 'lodash-es';
import moment from 'moment';
import numeral from 'numeral';

import { TacticAdjustmentValue } from '@/modules/shared/models/PricingTacticManagement';
import { ITag } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

import { DateTimeService } from './date-time.service';

const { t } = i18n.global;

moment.updateLocale('en', {
  relativeTime: {
    s: '1 min',
    ss: '<1 min',
    m: '1 min',
    mm: '%d mins',
    h: '1 hr',
    hh: '%d hrs',
    d: '1 day',
    dd: '%d days',
    M: '1 mth',
    MM: '%d mths',
  },
});

export class FormatService {
  public static abbriviateNumber(value: number): string {
    if (value >= 1000) {
      if (value % 1000 === 0) {
        return numeral(value).format('0a');
      } else {
        return numeral(value).format('0.0a');
      }
    } else {
      return value.toString();
    }
  }
  /**
   * amountWithCurrency formats a given amount to a specific currency format
   * @param amount is the amount of the currency to format
   * @param currencyCode the code of the curreny amount provided
   * @param fractionDigit how many fraction digits should be returned
   * @returns a formated string representing the amount in the currency provided
   */
  public static amountWithCurrency(amount: number, currencyCode?: string, fractionDigit = 0): string {
    if (isEmpty(currencyCode)) {
      currencyCode = AppSettingsModule.baseCurrency;
    }

    if (!isFinite(amount)) return '';

    return new Intl.NumberFormat(UserConfigModule.language, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: fractionDigit,
      maximumFractionDigits: fractionDigit,
    }).format(amount);
  }

  public static amountWithoutCurrency(amount: number, currencyCode?: string): string {
    if (isEmpty(currencyCode)) {
      currencyCode = AppSettingsModule.baseCurrency;
    }

    const formatter = new Intl.NumberFormat(UserConfigModule.language, {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    const parts = formatter
      .formatToParts(amount)
      .filter((part) => part.type !== 'currency')
      .map((part) => part.value);
    return parts.reduce((string, part) => string + part);
  }

  public static formatNumber(amount: number, decimals?: number): string {
    return new Intl.NumberFormat(UserConfigModule.language, {
      minimumFractionDigits: decimals ?? 0,
      maximumFractionDigits: decimals ?? 0,
    }).format(amount);
  }

  public static roundNumber(value: number, precision: number): string {
    const multiplier = Math.pow(10, precision || 0);
    return (Math.round(value * multiplier) / multiplier).toFixed(precision);
  }

  public static format(field: string, value: any): string {
    let formatted = value;

    switch (field) {
      case 'date':
        formatted = DateTimeService.formatDate({
          date: value,
          format: UserConfigModule.datePattern.toUpperCase(),
        });

        break;
      case 'dateTime':
        formatted = value.toString().substring(0, 5);

        break;
      case 'tags':
        formatted = '';
        if (value && value.map) {
          formatted = value.map((tag: ITag) => tag.name).join(', ');

          return formatted;
        }

        break;

      default:
        break;
    }

    return formatted;
  }

  public static addItemEvery(str: string, item: string, every: number): string {
    for (let i = 0; i < str.length; i++) {
      if (!(i % (every + 1))) {
        str = str.substring(0, i) + item + str.substring(i);
      }
    }
    return str.substring(1);
  }

  public static cabinClassCodeToOrdinalPosition(cabinCode: string, classCode: string): number {
    const cabin = AppSettingsModule.inventoryConfigurationProperties.cabins.find((cab) => cab.code === cabinCode);
    if (cabin) {
      return cloneDeep(cabin.classes)
        .sort((clsA, clsB) => clsA.order - clsB.order)
        .reverse()
        .filter((cls) => !cls.discrete && !cls.excludeInLAF && !cls.subClass)
        .findIndex((cls) => cls.code === classCode);
    }
    return -1;
  }

  public static ordinalPositionToClassCode(cabinCode: string, ordinalPosition: number): string | null {
    let output = null;
    const cabin = AppSettingsModule.inventoryConfigurationProperties.cabins.find((cab) => cab.code === cabinCode);
    if (cabin) {
      const cabinCls = cloneDeep(cabin.classes)
        .sort((clsA, clsB) => clsA.order - clsB.order)
        .reverse()
        .filter((cls) => !cls.discrete && !cls.excludeInLAF && !cls.subClass)[ordinalPosition];
      output = cabinCls?.code ?? null;
    }
    return output;
  }

  public static numberToTacticAdjustmentString(value: TacticAdjustmentValue): string {
    let result = '';

    switch (value) {
      case TacticAdjustmentValue.MINIMUM:
        result = t('minimum_short');
        break;
      case TacticAdjustmentValue.MINUSMINUS:
        result = '--';
        break;
      case TacticAdjustmentValue.MINUS:
        result = '-';
        break;
      case TacticAdjustmentValue.AVERAGE:
        result = t('average');
        break;
      case TacticAdjustmentValue.PLUS:
        result = '+';
        break;
      case TacticAdjustmentValue.PLUSPLUS:
        result = '++';
        break;
      case TacticAdjustmentValue.MAXIMUM:
        result = t('maximum_short');
        break;
      default:
        break;
    }

    return result;
  }

  /**
   * Create an human readable string from a date against the current date or two dates.
   * @returns human readable string of the given duration according to the moment js relative time format config for the given locale.
   */
  public static humanizeTimestamp(value1?: string, value2?: string): string {
    if (!value1) {
      return '';
    }

    const date1 = DateTimeService.getUTCDate({
      date: value1,
    });

    const date2 = DateTimeService.getUTCDate({
      date: value2 ? value2 : undefined,
    });

    const timeUnit = 'seconds';
    const diff = DateTimeService.calculateDateDifference(date1, date2, timeUnit);

    return `-${moment.duration(diff, timeUnit).humanize()}`;
  }
}
