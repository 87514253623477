import { FilterFieldType } from '@/modules/api/shared-contracts';

export const en = {
  control: {
    titles: {
      review_queue: 'Review Queue',
    },
    cross_filters: {
      bookings: 'Bookings',
      cabin_cf_diff: 'Δ CF %',
      cabin_cluster_performance: 'Performance Band',
      cabin_forecast_load_factor: 'Forecast Load Factor',
      cabin_laf: 'Lowest Available Fare',
      cabin_n_day_pickup: 'Cabin {cabin} Pickup ({n} day) | Cabin {cabin} Pickup ({n} days)',
      cabin_realtime_pickup: 'Realtime Pickup',
      cabin_rival_fares_diff: 'Δ RF %',
      cabin_rlaf: 'Active rLAF',
      cabin_rlaf_variance: 'Active rLAF Variance',
      cabin_rlaf_variance_data_science: 'Data Science rLAF Variance',
      cabin_shadow_rlaf: 'Shadow rLAF',
      cabin_shadow_rlaf_variance: 'Shadow rLAF Variance',
      capacity_load_factor: 'Capacity Load Factor',
      day_of_week: 'Day of Week',
      days_since_last_booking: 'Days Since Last Booking',
      days_since_last_user_action: 'Days Since Last User Action',
      days_since_last_user_review: 'Days Since Last User Review',
      departure_date: 'Departure Date',
      departure_time: 'Departure Time',
      lid_load_factor: 'Lid Load Factor',
      op_level: 'OP Level',
      performance_band_pickup: `Performance Band Pickup ({n} day) | Performance Band Pickup ({n} days)`,
      seat_availability_lowest_available_fare: 'Seats Available in LAF',
      ancillary_revenue: 'Ancillaries Share of Revenue',
    },
    cabin_x: `Cabin {cabin}`,
    reset_selection: 'Reset selection',
    zoom_to_selection: 'Zoom to selection',
    configuration: {
      option: {
        cabin: 'Cabin {cabin}',
        flight: 'Flight',
      },
    },
    columns: {
      equipment_type: 'Equipment Type',
      equipment_type_short: 'EqpT',
      equipment_version: 'Equipment Version',
      equipment_version_short: 'EqpV',
      expected_no_shows: 'Expected No Shows',
      expected_no_shows_short: 'Expected NS',
      overbooking_autopilot: 'Overbooking Autopilot',
      overbooking_autopilot_short: 'OVB Autopilot',
      overbooking_risk: 'Overbooking Risk',
      overbooking_risk_short: 'OVB Risk',
      recommended_overbooking_factor: 'Recommended Overbooking Factor',
      recommended_overbooking_factor_short: 'rOVB Factor',
      sales_configuration: 'Sales Configuration',
      sales_configuration_short: 'Config',
    },
    bulk_actions: {
      apply_recommended_overbooking: 'Apply rOVB',
      move_recommended_overbooking_risk_percentage_down: 'Decrease rOVB Risk %',
      move_recommended_overbooking_risk_percentage_up: 'Increase rOVB Risk %',
      set_overbooking_autopilot: 'Set OVB Autopilot',
    },
    filter_field_type: {
      [FilterFieldType.in]: 'Any',
      [FilterFieldType.all]: 'All',
      [FilterFieldType.none]: 'None',
      [FilterFieldType.noneOf]: 'None Of',
      [FilterFieldType.equal]: 'Equal',
    },
    errors: {
      route_filter_type_missing_from_query_payload:
        "No Route Filter Type selected. Use Flight Control Settings to use either the 'O&D' or 'Hub' option",
    },
  },
};
