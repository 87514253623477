import { CancelToken } from 'axios';
import { isNil } from 'lodash-es';
import { Moment } from 'moment';

import { CabinCode } from '@/modules/api/application/application-contracts';
import { CompetitorFare } from '@/modules/api/flight/competitive-fares/competitive-fares-contracts';
import { Page } from '@/modules/api/shared-contracts';
import { IOptimisationProfileLevel } from '@/modules/control/api/optimisation-profiles.contracts';
import { CustomerDefinedDataUIItem } from '@/modules/customer-defined-data/store/customer-defined-data.module.model';
import { SourceFlightAssignmentPayload } from '@/modules/data-import/api/data-import/data-import-contracts';
import { CrossingFlight } from '@/modules/details/api/crossing-flights.contracts';
import { CalendarEventModel } from '@/modules/event-management/api/event-management.contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { PromotionAssignmentModel } from '@/modules/promotions/api/promotion-contracts';
import { ClusterAssignment } from '@/modules/route-management/api/route-cluster.contracts';
import { RouteGroup } from '@/modules/route-management/api/route-groups.contracts';
import { RouteModel } from '@/modules/route-management/api/routes/routes.contracts';
import { PricingTactic } from '@/modules/shared/models/PricingTacticManagement';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { ITag } from '@/modules/tags';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';

export interface FlightLineModel {
  id: number;
  flightKey: string;
  uniqueFlightId: string;
  origin: string;
  destination: string;
  flightLine: string;
  calendarEvents?: FlightCalendarEvent[];
  captureDate: string;
  departureDate: string;
  departureTime: string;
  arrivalDate: string;
  arrivalTime: string;
  carrierCode?: string;
  cabins?: FlightLineCabin[];
  cabinAgreements?: CabinAgreement[];
  legs?: FlightLegModel[];
  tags?: ITag[];
  route?: RouteModel;
  routeId?: number;
  overnights: number;
  closed?: boolean;
  statusCodeManual?: boolean;
  codeShareBlockspace?: boolean;
  codeShareCappedFreeFlow?: boolean;
  userDateModified?: string;
  systemUserDateModified?: string;
  aircraftSaleableCapacity?: number;
  aircraftLid?: number;
  aircraftBookings?: number;
  aircraftGroupBookings: number;
  postDeparture?: PostDepartureFlight;
  aircraftLidLoadFactor?: number;
  aircraftCapacityLoadFactor?: number;
  aircraftMaxLegBookings?: number;
  isModified?: boolean;
  flightPath?: string;
  bookingsPickUps?: BookingsPickupModel[];
  performanceBandPickUps?: PerformanceBandPickupModel[];
  sourceFlight?: SourceFlight;
  sourceFlightId?: number;
  hasRealTimeBookings: boolean;
  averageFare?: number;
  pins?: number;
  lastRealTimeUpdate?: string;
  fareCurrency: string;
  lastBookingDate?: string;
  lastViewedDateTime?: string;
  linkedClassRuleId?: number;
  rivalRuleId?: number;
  routeGroups?: RouteGroup[];
  /**
   * Additional field that contains airline/customer defined data for the flight line.
   */
  customerDefinedData?: CustomerDefinedDataUIItem;
  /**
   * Additional fields when Ancillaries feature is enabled.
   */
  totalRevenue?: TotalRevenue;
  hasCalendarEvents?: boolean;
}

export type SlimFlightLineModel = Omit<FlightLineModel, 'sourceFlight'> & {
  sourceFlight?: SlimSourceFlight;
};

export interface FlightLineCabin {
  id?: {
    flightId: number;
    cabinCode: string;
  };
  code: CabinCode;
  order?: number;
  classes: FlightLineCabinClass[];
  inventoryMethod?: number;
  optimisationTactics?: string;
  shadowOptimisationTactics?: string;
  pricingTactic?: PricingTactic;
  pricingIncrement?: number;
  pricingAdjustment?: number;
  autopilot?: boolean;
  lowestAvailableFareClass?: string;
  lowestAvailableFareClassSeatAvailability?: number;
  recommendedLowestAvailableFareClass?: string;
  recommendedLowestAvailableFareClassVariance?: number;
  shadowRecommendedLowestAvailableFareClass?: string;
  shadowRecommendedLowestAvailableFareClassVariance?: number;
  lafFareValue?: number;
  lafPublishedFareValue?: number;
  lidLoadFactor?: number;
  capacityLoadFactor?: number;
  sumOfBoardings?: number;
  sumOfSold?: number;
  sumOfDeniedBoardings?: number;
  sumOfNoShows?: number;
  sumOfRevenue?: number;
  sumOfBoardedRevenue?: number;
  sumOfBookings: number;
  sumOfPinnedClasses?: number;
  sumOfGroupBookings: number;
  competitiveFares?: CompetitorFare[];
  rivalFares?: CompetitorFare[];
  isModified?: boolean;
  ssi?: number;
  minCfDeltaPercentage?: number | null;
  minRfDeltaPercentage?: number | null;
  bookingsPickUps?: BookingsPickupModel[];
  performanceBandPickUps?: PerformanceBandPickupModel[];
  optimisationProfileLevel?: IOptimisationProfileLevel;
  optimisationProfileLevelId?: number;
  shadowOptimisationProfileLevel?: IOptimisationProfileLevel;
  shadowOptimisationProfileLevelId?: number;
  averageFare?: number;
  fareValue?: CompetitorFare;
  maxLegBookings?: number;
  bookings?: number;
  realTimeBookings?: number;
  minLegAuthorizedCapacity?: number;
  minLegSaleableCapacity?: number;
  dataScienceRecommendation?: DataScienceRecommendation;
  negotiatedAllocations?: {
    totalAllocatedSeats: number;
    totalSoldSeats: number;
  };
  cluster?: ClusterAssignment;
  clusterOptions?: ClusterAssignment[];
  performanceScore?: number;
  performanceLabel?: PerformanceLabel;
  totalRevenue?: TotalRevenue;
}

export interface TotalRevenue {
  amount: number;
  fare?: Revenue;
  ancillary?: Revenue;
  surcharge?: Revenue;
  tax?: Revenue;
}

interface Revenue {
  amount: number;
  countOfSales: number;
  ratio: number;
  averagePerPassenger: number;
}

export interface FlightCalendarEvent {
  displayName: string;
  eventCluster: string;
}

export enum PerformanceLabel {
  WAY_BETTER_THAN_EXPECTED = 3,
  BETTER_THAN_EXPECTED = 2,
  BIT_BETTER_THAN_EXPECTED = 1,
  EXPECTED = 0,
  BIT_WORSE_THAN_EXPECTED = -1,
  WORSE_THAN_EXPECTED = -2,
  WAY_WORSE_THAN_EXPECTED = -3,
}

export interface FlightLineCabinClass {
  id?: {
    flightId: number;
    cabinCode: string;
    classCode: string;
  };
  flightId?: number;
  cabinCode?: string;
  classCode?: string;
  code: string;
  order?: number;
  parentClass?: string;
  boardings?: number;
  sold?: number;
  bookings?: number;
  updatedBookings?: number;
  realTimeBookings?: number;
  isBookingsUpdated?: boolean;
  groupBookings?: number;
  authorizationUnits?: number;
  updatedAuthorizationUnits?: number;
  ruleAuthorizationUnits?: number;
  recommendedAuthorizationUnits?: number;
  shadowRecommendedAuthorizationUnits?: number;
  recommendedProtection?: number;
  shadowRecommendedProtection?: number;
  protection?: number;
  updatedProtection?: number;
  groupOptional?: number;
  groupWaitlisted?: number;
  waitlist?: number;
  negotiatedAllottedSeats?: number;
  segmentAvailability?: number;
  effectiveDate?: string;
  updatedEffectiveDate?: string;
  discontinuedDate?: string;
  updatedDiscontinuedDate?: string;
  minControl?: number;
  updatedMinControl?: number;
  waitlistMax?: number;
  updatedWaitlistMax?: number;
  waitlistMaxPercentage?: number;
  updatedWaitlistMaxPercentage?: number;
  segmentLimit?: number;
  updatedSegmentLimit?: number;
  fareValue?: number;
  publishedFareValue?: number;
  averageFare?: number;
  displayOrder?: number;
  seatAvailability?: number;
  updatedSeatAvailability?: number;
  recommendedSeatAvailability?: number;
  shadowRecommendedSeatAvailability?: number;
  isModified?: boolean;
  sourceBookings?: number;
  sourceFinalSegmentAvailability?: number;
  sourceFinalGroupBookings?: number;
  sourceSegmentAvailability?: number;
  sourceGroupBookings?: number;
  sourceGroupBoardings?: number;
  isAuthorizationUnitsUpdated?: boolean;
  isProtectionUpdated?: boolean;
  isDiscontinuedDateUpdated?: boolean;
  isEffectiveDateUpdated?: boolean;
  isMinControlUpdated?: boolean;
  isSegmentLimitUpdated?: boolean;
  isAuthorizationUnitPinned?: boolean;
  isProtectionPinned?: boolean;
  minCabinSeatAvailability?: number;
  minCabinLid?: number;
  nos?: number;
  gns?: number;
  off?: number;
  gbo?: number;
  cbo?: number;
  cnb?: number;
  ugi?: number;
  ugo?: number;
  dgi?: number;
  dgo?: number;
  gos?: number;
  sbo?: number;
  snb?: number;
  vol?: number;
  dnb?: number;
  nor?: number;
  grpsold?: number;
  negoalloc?: number;
  revenue?: number;
  boardedRevenue?: number;
  postDeparture?: FlightLineCabinClass;
  bookingsPickUps?: BookingsPickupModel[];
  performanceBandPickUps?: PerformanceBandPickupModel[];
  discrete?: boolean;
  minPrice?: number;
  maxPrice?: number;
  promotion?: PromotionAssignmentModel;
  totalRevenue?: TotalRevenue;
  ancillaryCategories?: AncillaryCategory[];
}

export interface AncillaryCategory {
  categoryName: string;
  countOfSales: number;
  revenues: number;
  ratio: number;
}

export interface BookingsPickupModel {
  dayOffset: number;
  bookings: number;
  cancellations: number;
}

export interface PerformanceBandPickupModel {
  dayOffset: number;
  performanceBandDifference: number;
}

export interface FlightLegModel {
  id: number;
  legKey: string;
  carrierCode: string;
  departureDate: Moment;
  departureTime: string;
  arrivalTime: string;
  arrivalDate: Moment;
  origin: string;
  destination: string;
  serviceType?: string;
  statusCode?: string;
  equipmentType: string;
  equipmentVersion: string;
  salesConfig?: string;
  crossingFlights: number;
  flightNumber: string;
  cabins: FlightLegCabin[];
  isModified?: boolean;
  overnights: number;
}

export interface FlightLegCabin {
  absoluteAdjustment?: boolean;
  authorizedCapacity: number;
  balancedAdjustment: number;
  boardings?: number;
  bookings: number;
  capacityAdjustment?: number;
  capacityLoadFactor: number;
  capturedCapacityLoadFactor?: number;
  capturedLoadFactor?: number;
  code: CabinCode;
  connectingBookings: number;
  deniedBoardings?: number;
  expectedNoShows?: number;
  grossAvailability: number;
  isAuthorizedCapacityUpdated?: boolean;
  isBalancedAdjustmentUpdated?: boolean;
  isBookingsUpdated?: boolean;
  isUnbalancedAdjustmentUpdated?: boolean;
  leftoverCapacity: number;
  lid?: number;
  loadFactor: number;
  localBookings: number;
  maxRegradeAdjustment?: number;
  netAvailability: number;
  noShows?: number;
  operatingCapacity: number;
  overbookingAutopilot?: boolean;
  overbookingFactor: number;
  overbookingRisk: number;
  realTimeBookings?: number;
  recommendedOverbookingFactor: number;
  revenue?: number;
  saleableCapacity: number;
  seatAvailability: number;
  sold?: number;
  totalBlockSpace?: number;
  unbalancedAdjustment: number;
  updatedAuthorizedCapacity?: number;
  updatedBalancedAdjustment?: number;
  updatedBookings?: number;
  updatedUnbalancedAdjustment?: number;
}

export interface CabinAgreement {
  carrierCode: string;
  cabinCode: string;
  blocked: number;
  booked: number;
}

export interface SourceFlight {
  aircraftNdoBookings: number;
  aircraftNdoGroupBookings: number;
  finalAircraftBoardings: number;
  finalAircraftBookings: number;
  finalAircraftGroupBoardings: number;
  finalAircraftGroupBookings: number;
  aircraftNdoRevenue: number;
  finalAircraftBoardedRevenue: number;
  finalAircraftRevenue: number;
  aircraftNdoAverageFare: number;
  finalAircraftBoardedAverageFare: number;
  finalAircraftAverageFare: number;
  cabins: SourceFlightCabin[];
  origin: string;
  destination: string;
  departureDate: string;
  departureTime: string;
  fareCurrency: string;
  flightLine: string;
  flightId: number;
  carrierCode: string;
}

export type SlimSourceFlight = Omit<SourceFlight, 'cabins'>;

export interface SourceFlightCabin {
  cabinCode: string;
  cabinNdoBookings: number;
  cabinNdoGroupBookings: number;
  finalCabinBoardings: number;
  finalCabinBookings: number;
  finalCabinGroupBoardings: number;
  finalCabinGroupBookings: number;
  cabinNdoRevenue: number;
  finalCabinBoardedRevenue: number;
  finalCabinRevenue: number;
  cabinNdoAverageFare: number;
  finalCabinBoardedAverageFare: number;
  finalCabinAverageFare: number;
  classes: SourceFlightClass[];
}

export interface SourceFlightClass {
  classCode: string;
  finalBoardings: number;
  finalBookings: number;
  finalGroupBoardings: number;
  finalGroupBookings: number;
  ndoBookings: number;
  ndoGroupBookings: number;
  ndoRevenue: number;
  finalBoardedRevenue: number;
  finalRevenue: number;
  ndoAverageFare: number;
  finalBoardedAverageFare: number;
  finalAverageFare: number;
}

export interface PostDepartureFlight {
  cabins: PostDepartureCabin[];
  aircraftCommercialBoardings: number;
  aircraftBoardings: number;
  aircraftCommercialStandby: number;
  aircraftDeniedBoardings: number;
  aircraftDowngradesInto: number;
  aircraftDowngradesOut: number;
  aircraftFinalGroupSold: number;
  aircraftFinalSold: number;
  aircraftGoShow: number;
  aircraftGroupBoardings: number;
  aircraftGroupNoShows: number;
  aircraftNegotiatedAllocations: number;
  aircraftNoRecord: number;
  aircraftNoShows: number;
  aircraftOffloaded: number;
  aircraftRevenue: number;
  aircraftBoardedRevenue?: number;
  aircraftStaffBoardings: number;
  aircraftStaffStandby: number;
  aircraftUpgradesInto: number;
  aircraftUpgradesOut: number;
  aircraftVoluntaryOffload: number;
}

export interface PostDepartureCabin {
  code: string;
  classes: PostDepartureClass[];
  sumOfCommercialBoardings: number;
  sumOfBoardings: number;
  sumOfCommercialStandby: number;
  sumOfDeniedBoardings: number;
  sumOfDowngradesInto: number;
  sumOfDowngradesOut: number;
  sumOfGroupSold: number;
  sumOfSold: number;
  sumOfGoShow: number;
  sumOfGroupBoardings: number;
  sumOfGroupNoShows: number;
  sumOfNegotiatedAllocations: number;
  sumOfNoRecord: number;
  sumOfNoShows: number;
  sumOfOffloaded: number;
  sumOfRevenue: number;
  sumOfBoardedRevenue?: number;
  sumOfStaffBoardings: number;
  sumOfStaffStandby: number;
  sumOfUpgradesInto: number;
  sumOfUpgradesOut: number;
  sumOfVoluntaryOffload: number;
}

export interface PostDepartureClass {
  code: string;
  boardings: number;
  sold: number;
  displayOrder: number;
  nos: number;
  gns: number;
  off: number;
  gbo: number;
  cbo: number;
  cnb: number;
  ugi: number;
  ugo: number;
  dgi: number;
  dgo: number;
  gos: number;
  sbo: number;
  snb: number;
  vol: number;
  dnb: number;
  nor: number;
  grpsold: number;
  negoalloc: number;
  revenue: number;
  boardedRevenue?: number;
}

export interface DataScienceRecommendation {
  captureDate: string;
  forecastAverageFare: number;
  forecastLoadFactor: number;
  modelConfidence: number;
  recommendedFloorFareClass: string;
  recommendedLAF: string;
  recommendedSeatsAvailable: number;
  dataScienceLabels: string;
}

export interface NegotiatedAllocations {
  tourOperatorName: string;
  contractCode: string;
  classCode?: string;
  cabinCode?: string;
  price: number;
  soldSeats: number;
  allocatedSeats: number;
}

export class FlightFilterModel {
  public static fromFlight(flight: FlightLineModel | CrossingFlight): FlightFilterModel {
    const filter = new FlightFilterModel();
    filter.carrierCode = flight.carrierCode;
    filter.flightNumber = FlightService.getFlightNumbersFromFlightLine(flight.flightLine)[0].toString();
    filter.flightLine = flight.flightLine;
    filter.departureDate = DateTimeService.formatUTCDate({
      date: flight.departureDate,
    });
    filter.origin = flight.origin;
    filter.destination = flight.destination;

    return filter;
  }

  public page: string;
  public size: string;

  public carrierCode: string;
  public flightNumber?: string;
  public flightLine: string;
  public origin: string;
  public destination: string;
  public departureDate: string;
  public projection?: string;
  public ndo?: number | 'Days are invalid' | '' | '-';

  constructor(query?: { [key: string]: string | string[] | number | Date }) {
    if (query) {
      this.page = query.page ? query.page.toString() : '';
      this.size = query.size ? query.size.toString() : '';
      this.carrierCode = query.carrierCode ? query.carrierCode.toString() : '';
      this.flightNumber = query.flightNumber ? query.flightNumber.toString() : '';
      this.origin = query.origin ? query.origin.toString() : '';
      this.destination = query.destination ? query.destination.toString() : '';
      this.flightLine = query.flightLine ? query.flightLine.toString() : '';
      this.ndo = !isNil(query.ndo) ? parseInt(query.ndo.toString(), 10) : null;

      const systemStore = useSystemStore();

      this.departureDate =
        query.departureDate &&
        (query.departureDate instanceof Date || DateTimeService.isValidDate({ date: query.departureDate.toString() }))
          ? DateTimeService.formatUTCDate({
              date: query.departureDate.toString(),
            })
          : DateTimeService.formatUTCDate({
              date: systemStore.config!.captureDate,
            });
    }
  }

  public toQueryObject() {
    return {
      carrierCode: this.carrierCode,
      flightNumber: this.flightNumber,
      origin: this.origin,
      destination: this.destination,
      flightLine: this.flightLine,
      departureDate: DateTimeService.formatUTCDate({
        date: this.departureDate,
      }),
      page: this.page,
      size: this.size,
    };
  }
}

export interface IFlightnumbersService {
  getAll(): Promise<FlightNumber[]>;
}

export interface ISourceFlightService {
  bulkAssign(request: SourceFlightAssignmentPayload): Promise<void>;
}

export interface FlightNumber {
  origin: string;
  destination: string;
  flightNumber: string;
  carrierCode: string;
  flightPath: string;
  flightLine: string;
}

export interface SearchRequestParams {
  size: string;
}

export interface IOndsService {
  patch(id: number, tags: ITag[]): Promise<FlightLineModel>;
  search(
    request: FilterFieldDefinition[],
    cancelToken: CancelToken,
    gold?: boolean,
    filters?: SearchRequestParams,
  ): Promise<{ page: Page; content: SlimFlightLineModel[] }>;
  searchAll(
    request: FilterFieldDefinition[],
    cancelToken: CancelToken,
    params: {
      page: number;
      size: number;
    },
  ): Promise<SlimFlightLineModel[]>;
  get(filters: FlightFilterModel): Promise<FlightLineModel[] | SourceFlight[]>;
  getById(id: number): Promise<FlightLineModel>;
  requestRealTimeBookingUpdate(carrierCode: string, flightNumber: string, departureDate: string): Promise<{ status: string }>;
  getNegotiatedAllocations(flightId: number): Promise<NegotiatedAllocations[]>;
  updateLastViewed(flightId: number): Promise<string>;
  getCalendarEventsById(flightId: number): Promise<CalendarEventModel[]>;
}

export enum Actions {
  Revert = 'revert_to_last_capture',
  CorrectAuStructure = 'correct_au_structure',
  ResolveByMatchingOvb = 'resolve_leftover_capacity_to_ovb',
  ResolveByIgnoring = 'resolve_leftover_capacity_by_ignoring',
  SendFlightsToPss = 'send_flights_to_pss',
}
