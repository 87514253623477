import axios from 'axios';
import { StoreGeneric, getActivePinia } from 'pinia';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { Authority, LoginRequestModel } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { useClustersStore } from '@/modules/cluster-management/store/clusters.store';
import { useFlightReviewStore } from '@/modules/control/store/flight-review.store';
import { useOptimisationProfilesStore } from '@/modules/control/store/optimisation-profiles.store';
import { useCustomerDefinedDataStore } from '@/modules/customer-defined-data/store/customer-defined-data.store';
import { useCustomerSettingsStore } from '@/modules/customer-settings/store/customer-settings.store';
import { useFeaturesStore } from '@/modules/features/store/features.store';
import { FlightActionsModule } from '@/modules/flight-actions/store/modules/flight-actions.module';
import { logger } from '@/modules/monitoring';
import { useCalendarStore } from '@/modules/route-management/store/calendar.store';
import { useMarketInfoStore } from '@/modules/route-management/store/market-info.store';
import { useGlobalLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { useGlobalRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { SystemConfiguration } from '@/modules/system-settings/api/system/system.contracts';
import { useSystemStore } from '@/modules/system-settings/store/system.store';
import { useTagsStore } from '@/modules/tags/store/tags.store';
import { JwtService } from '@/services/jwt.service';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { ControlModule } from '@/store/modules/control.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

// State definition
export interface IAuthState {
  token: string;
  email: string;
  isAuthenticating: boolean;
  loginErrorMessage: string | null;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class Auth extends VuexModule implements IAuthState {
  // Default state
  public token: string = localStorage.getItem('token') || '';
  public username = '';
  public email = '';
  public hasFetchedSettings = false;
  public isAuthenticating = false;
  public loginErrorMessage: string | null = null;

  // Actions
  @Action
  public async login(credentials: LoginRequestModel): Promise<string | null> {
    this.setAuthenticating(true);
    this.setLoginErrorMessage(null);

    try {
      const response = await authService.login(credentials);
      const newToken = response.access_token;
      this.setToken(newToken);
      localStorage.setItem('token', newToken);

      const config = await this.getSettings();

      if (config) {
        return newToken;
      } else {
        this.setLoginErrorMessage('messages.unable_to_get_system_config');
        return null;
      }
    } catch (error) {
      this.setLoginErrorMessage('messages.unable_to_log_in');
      return null;
    } finally {
      this.setAuthenticating(false);
    }
  }

  @Action
  public async getToken(credentials: LoginRequestModel): Promise<string> {
    try {
      const response = await authService.login(credentials);
      const newToken = response.access_token;
      this.setToken(newToken);
      localStorage.setItem('token', newToken);

      return newToken;
    } catch (error) {
      this.setLoginErrorMessage('messages.unable_to_log_in');
      throw error;
    }
  }

  @Action
  public async getSettings(): Promise<SystemConfiguration | null> {
    const decodedToken = JwtService.decodeToken(this.token);
    this.setUsername(decodedToken.name);
    this.setEmail(decodedToken.user_name);

    await AppSettingsModule.getApplicationSettings();

    // Customer settings need to be loaded before User configuration
    // OwnFareSource is needed to set column state
    const customerSettingsStore = useCustomerSettingsStore();
    await customerSettingsStore.get();

    const clustersStore = useClustersStore();
    const customerDefinedDataStore = useCustomerDefinedDataStore();
    const optimisationProfilesStore = useOptimisationProfilesStore();
    const tagsStore = useTagsStore();
    const systemStore = useSystemStore();
    const calendarStore = useCalendarStore();
    const featuresStore = useFeaturesStore();
    const marketInfoStore = useMarketInfoStore();

    await Promise.all([
      customerSettingsStore.getMultiFactorAuthentication(),
      featuresStore.get(),
      authService.hasAuthority([Authority.RouteRead, Authority.FlightRead], decodedToken.authorities) ? marketInfoStore.get() : null,
      UserConfigModule.getUserConfiguration(decodedToken.user_name),
      optimisationProfilesStore.getAll(),
      ControlModule.getAircraftTypes(),
      authService.hasAuthority([Authority.TagRead], decodedToken.authorities) ? tagsStore.get() : null,
      FlightActionsModule.getFlightActionGroups(),
      authService.hasAuthority([Authority.ClustersRead, Authority.RouteRead, Authority.FlightRead], decodedToken.authorities)
        ? clustersStore.get()
        : null,
      authService.hasAuthority([Authority.LinkedClassRulesRead], decodedToken.authorities) ? useGlobalLinkedClassRule().getAll() : null,
      authService.hasAuthority([Authority.RivalRulesRead], decodedToken.authorities) ? useGlobalRivalRule().getAll() : null,
      customerSettingsStore.settings?.hasCustomerDefinedDataEnabled ? customerDefinedDataStore.getSchema() : null,
    ]);

    await systemStore.get();
    await calendarStore.get(systemStore.config!.captureDate);

    this.setHasFetchedSettings(true);

    return systemStore.config ?? null;
  }

  @Action
  public logOut(): void {
    this.clearModules();

    const activeStores = getActivePinia();

    if (activeStores) {
      // _s does exist and stores the Pinia stores, but it's internal so let's see for how long this keeps working 🤞
      // @ts-ignore-next-line
      activeStores?._s?.forEach((store: StoreGeneric) => {
        try {
          store.$reset();
        } catch (e) {
          logger.error(e as Error);
        }
      });
    }

    // $reset the flightReviewStore explicitly as it could be persisted in localstorage without a connected activeStores.
    // This way we make sure to also clear out the storage while not detaching it prom the persisted state
    const flightReviewStore = useFlightReviewStore();
    flightReviewStore.$reset();

    // Make sure requests won't have the 'invalid' token anymore
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    localStorage.removeItem('gridColumnStates');
    this.setToken('');
  }

  // Mutations
  @Mutation
  private setToken(token: string): void {
    this.token = token;
  }

  @Mutation
  private clearModules(): void {
    ControlModule.clearSearchAndQuery();
  }

  @Mutation
  private setEmail(email: string): void {
    this.email = email;
  }

  @Mutation
  private setUsername(username: string): void {
    this.username = username;
  }

  @Mutation
  public setHasFetchedSettings(hasFetchedSettings: boolean): void {
    this.hasFetchedSettings = hasFetchedSettings;
  }

  @Mutation
  private setAuthenticating(isAuthenticating: boolean): void {
    this.isAuthenticating = isAuthenticating;
  }

  @Mutation
  private setLoginErrorMessage(errorMessage: string | null): void {
    this.loginErrorMessage = errorMessage;
  }
}

export const AuthModule = getModule(Auth);
